
const generateTitle = (text: string, pageBreak?: string): object => {
  return {
    table: {
      widths: [558],
      body: [
        [{ text: text.toUpperCase(), bold: true, color: '#FFFFFF', margin: [40, 5, 5, 5] }]
      ]
    },
    layout: {
      fillColor: '#aaaaaa',
      defaultBorder: ''
    },
    alignment: 'left',
    margin: [-40, 30, 0, 10],
    ...(pageBreak ? { pageBreak } : {}),
    pageOrientation: 'portrait'
  }
}

const generateSubTitle = (text: string): object => {
  return {
    table: {
      widths: [518],
      body: [
        [{ text: text.toUpperCase(), bold: true, color: '#FFFFFF', margin: [5, 5, 5, 5] }]
      ]
    },
    layout: {
      fillColor: '#aaaaaa',
      defaultBorder: ''
    },
    alignment: 'left',
    margin: [0, 10, 0, 10]
  }
}

export default {
  generateTitle,
  generateTitleLandscape: (text: string, pageBreak?: string): object => {
    const base: any = {
      ...generateTitle(text, pageBreak),
      pageOrientation: 'landscape'
    }
    base.table.widths[0] = 795
    return base
  },
  generateSubTitle,
  generateSubTitleLandscape: (text: string) => {
    const base: any = {
      ...generateSubTitle(text),
      pageOrientation: 'landscape'
    }
    base.table.widths[0] = 755
    return base
  },
  generateParagraph: (text: string, options?: {
    alignment?: string;
    fontSize?: number;
    pageBreak?: string;
    italics?: boolean;
    bold?: boolean;
  }) => {
    options = options || {}
    return {
      width: 'auto',
      text,
      color: '',
      fontSize: options.fontSize || 12,
      alignment: options.alignment || 'justify',
      bold: Boolean(options.bold),
      italics: Boolean(options.italics),
      ...(options.pageBreak ? { pageBreak: options.pageBreak } : {})
    }
  },
  ucfirst: (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
  },
  generateCenteredText: (text: string, fontSize?: number, bold?: boolean, color?: string, italic?: boolean, pageBreak?: string): object[] => {
    return [
      { width: '*', text: '' },
      {
        width: 'auto',
        text,
        color: color || '',
        fontSize: fontSize || 22,
        alignment: 'center',
        bold,
        italics: Boolean(italic),
        pageBreak
      },
      { width: '*', text: '' }
    ]
  }
}
