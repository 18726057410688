
export default {
  title: 'Reporte Individual POR',
  copyright: 'OCC - Todos los derechos reservados.',
  cover_table_header_1: 'Respuestas esperadas',
  cover_table_header_2: 'Respuestas obtenidas',
  cover_table_header_3: 'Porcentaje de respuestas',
  cover_months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  background_1: 'Introducción',
  background_2: '¿Para qué es importante el Trabajo en Equipo?',
  background_3: '¿En qué consiste el modelo POR?',
  background_4: '¿Qué contiene el reporte?',
  background_5: 'Recomendaciones',
  background_6: 'Resultados generales',
  background_7: 'Personas',
  background_8: 'Organización',
  background_9: 'Resultados',
  background_10: 'Fortalezas y Oportunidades de Mejora',
  background_11: 'Brechas',
  background_12: 'Seguimiento (Tendencias)',
  index_title: 'Contenido del informe',
  index: [
    '01. ¿Para qué es importante el Trabajo en Equipo?',
    '02. ¿En qué consiste el modelo de Trabajo en Equipo POR®?',
    '03. ¿Qué contiene el reporte?',
    '04. Recomendaciones',
    '05. Resultados generales',
    '06. Resultados por conducta: Dimensión Personas',
    '07. Resultados por conducta: Dimensión Organización',
    '08. Resultados por conducta: Dimensión Resultados',
    '09. Fortalezas y Oportunidades de Mejora',
    '10. Brechas',
    '10. Seguimiento (Tendencias)',
    '11. Plan de trabajo'
  ],
  index_msg: '“Trabajar en equipo no es una virtud, es una decisión” J. Katzenbach.',
  results_title_1: '5. Resultados generales',
  result_legend_table_1: 'Rango de datos (min-max)',
  result_legend_table_2: 'Individual',
  result_legend_table_3: 'Equipo',
  result_legend_table_4: 'Fortalezas',
  result_legend_table_5: 'Oportunidades de mejora',
  results_title_1_table_header_1: 'Individual',
  results_title_1_table_header_2: 'Equipo',
  results_title_1_table_header_3: 'Tendencia',
  results_info_1: 'La evaluación individual es la forma en la que usted se auto valora y el equipo es el promedio de como cada uno de ustedes valora al equipo de trabajo.',
  results_info_2: 'Esta gráfica muestra los resultados generales de acuerdo con el promedio de las valoraciones del equipo.',
  results_title_2: '06. Resultados por conducta: Dimensión Personas',
  results_info_3: 'A continuación se presenta, para cada conducta el puntaje promedio obtenido por el Equipo y el rango de respuestas. Teniendo en cuenta el resultado de las 3 Dimensiones, se señalan en color azul los 6 puntajes más altos (Fortalezas) y en naranja los 6 más bajos (Oportunidades de Mejora). La columna Tendencia muestra si hubo cambio positivo o negativo en el promedio del equipo de acuerdo con la anterior medición (no aplica para una primera aplicación de la encuesta).',
  results_title_3: '07. Resultados por conducta: Dimensión Organización',
  results_info_4: 'A continuación se presenta, para cada conducta el puntaje promedio obtenido por el Equipo y el rango de respuestas. Teniendo en cuenta el resultado de las 3 Dimensiones, se señalan en color azul los 6 puntajes más altos (Fortalezas) y en naranja los 6 más bajos (Oportunidades de Mejora). La columna Tendencia muestra si hubo cambio positivo o negativo en el promedio del equipo de acuerdo con la anterior medición (no aplica para una primera aplicación de la encuesta).',
  results_title_4: '08. Resultados por conducta: Dimensión Resultados',
  results_info_5: 'A continuación se presenta, para cada conducta el puntaje promedio obtenido por el Equipo y el rango de respuestas. Teniendo en cuenta el resultado de las 3 Dimensiones, se señalan en color azul los 6 puntajes más altos (Fortalezas) y en naranja los 6 más bajos (Oportunidades de Mejora). La columna Tendencia muestra si hubo cambio positivo o negativo en el promedio del equipo de acuerdo con la anterior medición (no aplica para una primera aplicación de la encuesta).',
  results_title_2_table_header_1: 'Individual',
  results_title_2_table_header_2: 'Equipo',
  results_title_2_table_header_3: 'Tendencia',
  results_title_3_table_header_1: 'Individual',
  results_title_3_table_header_2: 'Equipo',
  results_title_3_table_header_3: 'Tendencia',
  results_title_4_table_header_1: 'Individual',
  results_title_4_table_header_2: 'Equipo',
  results_title_4_table_header_3: 'Tendencia',
  rank_title: '09. Fortalezas y Oportunidades de Mejora',
  rank_title_info: 'Estas son las conductas con los 6 puntajes más altos (Fortalezas) y los 6 más bajos (Oportunidades de Mejora) según el promedio de las valoraciones del equipo.',
  rank_table_1_header: 'Fortalezas',
  rank_table_1_sub_header_1: 'DIMENSIÓN',
  rank_table_1_sub_header_2: 'ATRIBUTO',
  rank_table_1_sub_header_3: 'CONDUCTA',
  rank_table_1_sub_header_4: 'INDIVIDUAL',
  rank_table_1_sub_header_5: 'EQUIPO',
  rank_table_1_sub_header_6: 'TENDENCIA',
  rank_table_2_header: 'Oportunidades de Mejora',
  rank_table_2_sub_header_1: 'DIMENSIÓN',
  rank_table_2_sub_header_2: 'ATRIBUTO',
  rank_table_2_sub_header_3: 'CONDUCTA',
  rank_table_2_sub_header_4: 'INDIVIDUAL',
  rank_table_2_sub_header_5: 'EQUIPO',
  rank_table_2_sub_header_6: 'TENDENCIA',
  gaps_title: '09. Brechas',
  gaps_title_info: 'La siguiente tabla muestra las brechas más altas positivas (cuando mi autovaloración es mayor que el promedio del equipo) y negativas (cuando mi autovaloración es menor que el promedio del equipo).',
  gaps_table_1_header: 'Brechas más altas positivas',
  gaps_table_1_sub_header_1: 'DIMENSIÓN',
  gaps_table_1_sub_header_2: 'ATRIBUTO',
  gaps_table_1_sub_header_3: 'CONDUCTA',
  gaps_table_1_sub_header_4: 'INDIVIDUAL',
  gaps_table_1_sub_header_5: 'EQUIPO',
  gaps_table_1_sub_header_6: 'BRECHA',
  gaps_table_2_header: 'Brechas más altas negativas',
  gaps_table_2_sub_header_1: 'DIMENSIÓN',
  gaps_table_2_sub_header_2: 'ATRIBUTO',
  gaps_table_2_sub_header_3: 'CONDUCTA',
  gaps_table_2_sub_header_4: 'INDIVIDUAL',
  gaps_table_2_sub_header_5: 'EQUIPO',
  gaps_table_2_sub_header_6: 'BRECHA',
  trend_title: '10. Seguimiento (Tendencias)',
  trend_info: 'Las siguientes tablas solo aplica cuando se hace una medición de seguimiento. En esta se presentan los 6 mayores cambios o saltos Positivos del Equipo (donde el promedio actual es mayor al anterior) y Negativos (donde el promedio actual disminuye con respecto al anterior).',
  trend_msg: 'Disponible a partir de la segunda medición',
  trend_table_1_title: 'Tendencias Positivas',
  trend_table_1_header_1: 'DIMENSIÓN',
  trend_table_1_header_2: 'ATRIBUTO',
  trend_table_1_header_3: 'CONDUCTA',
  trend_table_1_header_4: 'INDIVIDUAL',
  trend_table_1_header_5: 'ANTERIOR',
  trend_table_1_header_6: 'TENDENCIA',
  trend_table_2_title: 'Tendencias Negativas',
  trend_table_2_header_1: 'DIMENSIÓN',
  trend_table_2_header_2: 'ATRIBUTO',
  trend_table_2_header_3: 'CONDUCTA',
  trend_table_2_header_4: 'INDIVIDUAL',
  trend_table_2_header_5: 'ANTERIOR',
  trend_table_2_header_6: 'TENDENCIA',
  plan_title: 'PLAN DE ACCIÓN INDIVIDUAL – TRABAJO EN EQUIPO – MODELO POR',
  plan_msg: 'Basados en la información del reporte y el conocimiento de nosotros mismos, estas son las acciones y compromisos para mejorar nuestro trabajo colaborativo',
  plan_table_1_col_1: 'NOMBRE DEL EQUIPO',
  plan_table_1_col_2: 'FECHA',
  plan_table_2_header_1: 'ACCIONES – ¿Qué voy a comenzar a hacer?',
  plan_table_2_header_2: 'RESULTADO ESPERADO',
  plan_table_2_header_3: 'SEGUIMIENTO',
  plan_table_3_header_1: 'RENUNCIAS – ¿Qué voy a dejar de hacer?',
  plan_table_3_header_2: 'RESULTADO ESPERADO',
  plan_table_3_header_3: 'SEGUIMIENTO',
  plan_table_4_header_1: 'MIS COMPROMISOS – Conductas',
  plan_table_4_header_2: 'RESULTADO ESPERADO',
  plan_table_4_header_3: 'SEGUIMIENTO'
}
