
import PdfUtil from '../utils/pdf'

const getNode = () => ({ alignment: 'center', text: '\n' })

const getBaseHeader = (text) => {
  return {
    bold: true,
    fontSize: 13,
    color: 'white',
    text,
    colSpan: 1,
    alignment: 'center'
  }
}

const getFirmCol = (text) => ({ ...getBaseHeader(text), alignment: 'right', color: 'black' })

const getPlanFirmTable = (vueInstance) => {
  const table = {
    widths: ['30%', '70%'],
    headerRows: 0,
    body: [
      [getFirmCol(vueInstance.$t('Reports.Individual.plan_table_1_col_1')), getNode()],
      [getFirmCol(vueInstance.$t('Reports.Individual.plan_table_1_col_2')), getNode()]
    ]
  }

  return {
    table,
    fontSize: 10
  }
}

const getPlanTable = (vueInstance, base, color1, color2, color3) => {
  const table = {
    widths: ['36%', '32%', '32%'],
    headerRows: 1,
    body: [
      [
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}header_1`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}header_2`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}header_3`))
      ]
    ]
  }
  for (let i = 1; i <= 5; i++) {
    table.body.push([getNode(), getNode(), getNode()])
  }
  return {
    table,
    fontSize: 10,
    layout: {
      fillColor: (rowIndex) => {
        if (rowIndex === 0) {
          return color1
        }
        return (rowIndex % 2 === 0) ? color2 : color3
      },
      hLineWidth: () => 1,
      vLineWidth: () => 1,
      hLineColor: () => 'white',
      vLineColor: () => 'white'
    }
  }
}

export default {
  methods: {
    $generatePlan () {
      return [
        PdfUtil.generateTitle(this.$t('Reports.Individual.plan_title'), 'before'),
        {
          columns: PdfUtil.generateCenteredText(this.$t('Reports.Individual.plan_msg'), 14, true),
          margin: [0, 10, 0, 0]
        },
        { text: '\n' },
        getPlanFirmTable(this),
        { text: '\n' },
        getPlanTable(this, 'plan_table_2_', '#4472c4', '#cdd4ea', '#e8ebf5'),
        { text: '\n' },
        getPlanTable(this, 'plan_table_3_', '#4472c4', '#cdd4ea', '#e8ebf5'),
        { text: '\n' },
        getPlanTable(this, 'plan_table_4_', '#4472c4', '#cdd4ea', '#e8ebf5')
      ]
    }
  }
}
