
export default {
  threadOrganizational: {
    modal_confirm_report_title: 'Confirme generación del Reporte Organizacional',
    modal_workshop_cost: 'Costo del Reporte Organizacional',
    opetaion_init: 'Se ha dado inicio a un proceso de descarga "Reporte organizacional", este proceso puede tardar varios minutos, puede ver su progreso al refrescar la página',
    input_generate_report: 'Generar reporte'
  },
  threadOrganizationalReportExec: {
    input_generating_report: 'Generando reporte',
    input_update_progress: 'Actualizar progreso',
    input_failed_generation: 'Generación fallida',
    input_download_report: 'Descargar reporte'
  },
  threadIndividualReport: {
    opetaion_init: 'Se ha dado inicio a un proceso de descarga "Reporte Individual", este proceso puede tardar varios minutos, puede ver su progreso al refrescar la página',
    input_generate_report: 'Generar reporte',
    input_no_participation: 'Sin participación'
  },
  threadIndividualReportExec: {
    input_generating_report: 'Generando reporte',
    input_update_progress: 'Actualizar progreso',
    input_failed_generation: 'Generación fallida',
    input_download_report: 'Descargar reporte'
  }
}
