<template>
  <v-container>
    <template>
      <v-row>
        <v-col>
          <h4 class="display-1">{{ $t('Views.Questionnaires.edit.title') }}</h4>
        </v-col>
      </v-row>
      <v-card>
        <v-card-text>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="(dimention, ix) in questionnaire.evaluations" :key="ix">
              <v-expansion-panel-header> {{ dimention.name[lang] }} </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row v-for="(attribute, i) in dimention" :key="i">
                  <v-col cols="12" xs="12" v-if="attribute.name">
                    <v-tooltip bottom color="green">
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          light outlined
                          :label="$t(`Views.Questionnaires.edit.input_attribute`)"
                          name="attribute"
                          v-model="attribute.name[lang]"
                          @keypress.enter="sendForm"
                          @blur="sendForm"
                          v-on="on"
                          outline
                          append-icon="mdi-content-save-edit-outline"
                          color="green"
                        ></v-text-field>
                      </template>
                      <span>
                        {{ $t('Views.Questionnaires.edit.tooltip_info') }}
                      </span>
                    </v-tooltip>
                  </v-col>
                  <template v-for="index in 5">
                    <v-col cols="12" sm="6" v-for="(question, q) in attribute[`behavior_0${index}`]" :key="index + '-' + q">
                      <v-tooltip bottom color="blue" >
                        <template v-slot:activator="{ on }" >
                          <v-text-field
                            light outlined
                            :label="$t(`Views.Questionnaires.edit.input_question_${q}`)"
                            name="question"
                            v-model="(question || {})[lang]"
                            @keypress.enter="sendForm"
                            @blur="sendForm"
                            v-on="on"
                            outline
                            append-icon="mdi-content-save-edit-outline"
                            color="blue"
                          ></v-text-field>
                        </template>
                        <span>
                          {{ $t('Views.Questionnaires.edit.tooltip_info') }}
                        </span>
                      </v-tooltip>
                    </v-col>
                  </template>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-row>
            <v-col align="end">
              <v-btn to="/questionnaires" text>
                {{ $t('Views.Questionnaires.edit.btn_back') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </template>
  </v-container>
</template>

<script>

import Vue from 'vue'
import { mapState } from 'vuex'

import questionnairesService from '../../services/questionnaires'

export default Vue.extend({
  data () {
    return {
      questionnaire: {},
      lang: 'es'
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  created () {
    this.getQuestionnaire()
  },
  methods: {
    getQuestionnaire () {
      this.$store.dispatch('loading/show')
      return questionnairesService.getOne(this.$route.params.slug)
        .then((res) => {
          this.questionnaire = res
          this.$store.dispatch('loading/hide')
          if (!this.questionnaire) {
            this.$router.push('404')
          }
        }).catch(() => {
          this.$store.dispatch('loading/hide')
          this.$router.push('404')
        })
    },
    sendForm () {
      this.$store.dispatch('loading/show')
      return questionnairesService.edit(this.questionnaire.slug, this.questionnaire)
        .then(() => {
          this.$store.dispatch('alert/success', this.$t('Views.Questionnaires.edit.edited_succesfully'))
          this.$store.dispatch('loading/hide')
        }).catch(() => {
          this.$store.dispatch('alert/error', this.$t('Views.Questionnaires.edit.edited_error'))
          this.$store.dispatch('loading/hide')
        })
    }
  }
})
</script>
