
import PdfUtil from '../utils/pdf'

const getTrend = (hasPrevious, trend, round) => {
  return hasPrevious ? { text: round(trend, 2), alignment: 'right', margin: [0, 0, 25, 0], fontSize: 10 } : { text: '--', alignment: 'center' }
}

const getScore = (score, round) => {
  return { text: round(score, 2), alignment: 'center', fontSize: 10 }
}

const getBaseHeader = (text, colSpan, fontSize) => {
  return {
    bold: true,
    fontSize: fontSize || 11,
    color: 'black',
    text: text,
    colSpan: colSpan || 1,
    alignment: 'center'
  }
}
const getSubHeader = (text) => ({ ...getBaseHeader(PdfUtil.ucfirst(text)), fontSize: 10 })
const getText = (text) => ({ text: text, fontSize: 9, color: 'black' })
const getBehavior = (behavior) => {
  const b = behavior.split('_')
  return `${b[0]}_0${b[1]}`
}

const getResultTable = (vueInstance, base, elements, questionEvaluations) => {
  const table = {
    widths: ['12%', '17%', '44%', '10%', '7%', '10%'],
    headerRows: 2,
    body: [
      [getBaseHeader(vueInstance.$t(`Reports.Individual.${base}header`), 6), '', '', '', '', ''],
      [
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}sub_header_1`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}sub_header_2`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}sub_header_3`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}sub_header_4`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}sub_header_5`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}sub_header_6`))
      ]
    ]
  }

  for (const item of elements) {
    table.body.push([
      getSubHeader(questionEvaluations[item.dimention].name.es),
      getSubHeader(questionEvaluations[item.dimention][item.attribute].name.es),
      getText(questionEvaluations[item.dimention][item.attribute][getBehavior(item.behavior)].generalEvaluation.es),
      getScore(item.score, vueInstance.round),
      getScore(item.second, vueInstance.round),
      getTrend(
        vueInstance.hasPrevious,
        item.trend,
        vueInstance.round
      )
    ])
  }

  return {
    table,
    margin: [0, 7, 0, 0]
  }
}

export default {
  methods: {
    $generateRank () {
      return [
        PdfUtil.generateTitleLandscape(this.$t('Reports.Individual.rank_title'), 'before'),
        PdfUtil.generateParagraph(this.$t('Reports.Individual.rank_title_info'), { fontSize: 8 }),
        getResultTable(this, 'rank_table_1_', this.strengthsItems, this.evaluation.questionnaire.evaluations),
        getResultTable(this, 'rank_table_2_', this.improvementItems, this.evaluation.questionnaire.evaluations)
      ]
    }
  }
}
