
import Components from './components'
import Reports from './reports'
import Views from './views'

import auth from './auth'
import errors from './errors'
import help from './help'
import instructive from './instructive'

export default {
  Components,
  Reports,
  Views,
  /* General Labels */
  title: 'OCC Solutions - Pulse',
  active: 'Activo',
  inactive: 'Inactivo',
  no_data: 'No hay registros',
  registration_updated_successfully: 'Registro actualizado con éxito',
  record_saved_successfully: 'Registro guardado exitosamente',
  error_trying_update: 'Hubo un error al intentar actualizar',
  error_trying_save: 'Se produjo un error al intentar guardar',
  go_back: 'Volver',
  results_per_pages: 'Resultados por páginas',
  go_to_manual: 'Haga click aquí para ir a la sección de manuales',
  page_all: 'Todos',
  /* Models */
  models: {
    enterprises: 'Empresas',
    groups: 'Grupos Empresariales',
    pulse: 'Pulse'
  },
  waiting: 'Por favor espere',
  maintenance: 'Página en construcción',
  // tslint:disable-next-line:max-line-length
  maintenance_text: 'Trabajamos cada día para lograr los objetivos<br/>y darle mejores resultados y experiencias.',
  /* Labels grouped by modules or type */
  auth,
  errors,
  help,
  instructive,
  page_text: 'de'
}
