
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-row justify="space-between" class="px-8">
          <h4 class="display-1 left">{{ evaluation.name }}</h4>
          <v-chip
            class="mb-3 white--text right"
            color="primary"
            v-if="evaluation.displayName"
          >{{ evaluation.displayName }}</v-chip>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-card>
          <v-row class="ma-3" justify="center">
            <v-col cols="12">
              <v-select
                v-model="typeReport"
                :items="items"
                :label="$t('Views.Evaluations.report.select_report_type')"
                item-value="value"
                item-text="name"
              />
            </v-col>
          </v-row>
          <!-- General -->
          <v-card-text v-if="typeReport === 'organizational'">
            <h5 class="headline mb-2">{{ $t('Views.Evaluations.report.required_general_title') }}</h5>
            <p class="mt-5 mb-12">{{ $t('Views.Evaluations.report.required_general_desc') }}</p>
            <v-row justify="center">
              <span v-if="displayReport">
                <x-thread-organizational-report
                  v-if="validEvaluation"
                  :poll-id="this.$route.params.id"
                  :disable-button="disableButton"
                  :thread="organizationalThread"
                  :button-text="$t('Views.Evaluations.report.general_report')"
                ></x-thread-organizational-report>
                <div v-else class="headline red--text">{{ $t('Views.Evaluations.report.no_members') }}</div>
              </span>
            </v-row>
          </v-card-text>
          <!-- Individual -->
          <v-card-text v-if="typeReport === 'individual'">
            <h5 class="headline mb-2">{{ $t('Views.Evaluations.report.individual_title') }}</h5>
            <p class="mt-5 mb-12">{{ $t('Views.Evaluations.report.individual_desc') }}</p>
            <v-row justify="center">
              <v-col cols="12">
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="pt-4 text-center">
                          {{ $t('Views.Evaluations.report.header_members') }}
                        </th>
                        <th class="pt-4 text-center">
                          {{ $t('Views.Evaluations.report.header_action') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, $i) in evaluation.evaluated"
                        :key="$i"
                      >
                        <td class="pt-5 text-left">
                          {{ item.employee.firstName }} {{ item.employee.lastName }} ({{ identifyTypes[item.employee.identifyTypeId] }}{{ item.employee.identifyDocument }})
                        </td>
                        <td class="text-center py-2">
                          <x-thread-individual-report
                            :poll-id="evaluation._id"
                            :poll-status="item.status"
                            :disable-button="disableButton"
                            :thread="individualsThreads[item.employee.id]"
                            :evaluated-id="item.employee.id"
                            :evaluated-name="`${item.employee.firstName} ${item.employee.lastName} (${identifyTypes[item.employee.identifyTypeId]}${item.employee.identifyDocument})`"
                          ></x-thread-individual-report>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider class="mt-4"></v-divider>
          <v-row justify="center">
            <v-col cols="10">
              <v-btn class="ml-5 my-3" @click="goBack()" large block>{{ $t('Views.Evaluations.report.input_back') }}</v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="showModal" width="600px">
      <v-card>
        <v-card-text style="padding: 24px 20px;">{{ $t('Views.Evaluations.report.no_answers_modal_msg') }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            large
            block
            color="#3898d9"
            @click="showModal = !showModal"
          >{{ $t('Views.Evaluations.report.input_accept') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue'
import XThreadOrganizationalReport from '../reports/thread_organizational_report'
import XThreadIndividualReport from '../reports/thread_individual_report'

import evaluationService from '../../services/evaluations'
import identifyTypesService from '../../services/identify-types'

const getInitials = (text) => {
  return text.trim().split(' ').map(t => t.slice(0, 1)).join('').toUpperCase()
}

export default Vue.extend({
  components: {
    XThreadOrganizationalReport,
    XThreadIndividualReport
  },
  data () {
    return {
      disableButton: false,
      disableIndividualButton: false,
      evaluation: {},
      displayReport: true,
      showModal: false,
      typeReport: 'organizational',
      evaluated: '',
      validEvaluation: true,
      identifyTypes: {},
      items: [
        { name: this.$i18n.t('Views.Evaluations.report.required_general_title'), value: 'organizational' },
        { name: this.$i18n.t('Views.Evaluations.report.individual_title'), value: 'individual' }
      ],
      organizationalThread: {},
      individualsThreads: {}
    }
  },
  created () {
    identifyTypesService.list()
      .then((res) => {
        res.items.forEach(et => {
          this.identifyTypes[et.id] = getInitials(et.translate.label) + ' - '
        })
        return this.getEvaluation()
      })
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    getEvaluation () {
      this.$store.dispatch('loading/show')
      evaluationService.findByIdToReport(this.$route.params.id)
        .then((res) => {
          if (res.status !== 'completed') {
            this.goBack()
            return
          }
          this.evaluation = res
          this.validateEvaluation(res.evaluated)
          this.disableButton = true
          this.disableIndividualButton = false
          this.$store.dispatch('loading/hide')
          return evaluationService.currentThreadsById(this.$route.params.id)
        })
        .then((res) => {
          res.forEach((thread) => {
            if (thread.data.type === 'organizational') {
              this.organizationalThread = thread
            } else {
              this.individualsThreads[thread.data.individualReference] = thread
            }
          })
        })
        .catch(err => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    reloadReport () {
      this.disableButton = false
      this.$nextTick().then(() => { this.disableButton = true })
    },
    validateEvaluation (evaluated) {
      const validEvaluated = evaluated.filter((ev) => ev.status === 'completed')
      this.validEvaluation = validEvaluated.length > 0
      this.showModal = !this.validEvaluation
    }
  }
})
</script>
