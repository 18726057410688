
import echarts from 'echarts'

import ColorUtils from '../utils/color'

interface IRich {
  fontSize: number;
  color: string;
  align: string;
}

interface IData {
  name: string;
  value: number;
  dimention: string;
  attr: string;
  others?: Array<{
    value: number;
    color: string;
  }>;
}

const colors = ColorUtils.rgb()

const base = {
  type: 'bar',
  coordinateSystem: 'polar',
  stack: 'a',
  barWidth: '100%'
}

const baseBlank = {
  ...base,
  name: 'blank',
  color: 'rgba(0,0,0,0)'
}

const insertLineBreak = (text: string, lineLength?: number) => {
  lineLength = lineLength || 20
  let texto = text.split('')
  const long = texto.length
  if (long > lineLength) {
    for (let i = lineLength; i >= 0; i--) {
      if (texto[i] === ' ') {
        texto[i] = '\n'
        const restan = long - (i + 1)
        if (restan > lineLength) {
          const text1 = texto.splice(i + 1, restan).join('')
          const resto = insertLineBreak(text1)
          texto = texto.splice(0, i + 1).concat(resto.split(''))
        }
        break
      }
    }
  }
  return texto.join('')
}

const getColor = (dimention: string, attr: string) => {
  return colors[dimention][attr]
}

const baseRich: IRich = { fontSize: 35, color: '#000000', align: 'center' }

const getHeaderNode = (
  name: string,
  color: string,
  value: number,
  secondValues?: Array<{ value: number; color: string }>
) => {
  const othersRich: {[key: string]: IRich} = {}
  let othersText = ''
  if (!secondValues || !secondValues.length) {
    othersText = '{b| | --}'
    othersRich.b = baseRich
  } else {
    const letters = ['b', 'c', 'd']
    let idx = 0
    for (const item of secondValues) {
      othersRich[letters[idx]] = { ...baseRich, color: item.color }
      othersText = othersText.concat(`{${letters[idx++]}| | ${item.value}}`)
    }
  }
  return {
    value: `{a|${value}}${othersText}\n{a|${insertLineBreak(name)}}`,
    textStyle: {
      rich: {
        a: { ...baseRich, color },
        ...othersRich
      }
    }
  }
}

const setData = (idx: number, value: number) => {
  const baseData = [0, 0, 0, 0, 0, 0, 0, 0, 0]
  baseData[idx] = value
  return baseData
}

const setSpace = (idx: number, value: number) => ({
  ...baseBlank,
  data: setData(idx, value)
})

const setLine = (idx: number, color: string) => ({
  ...base,
  color,
  name: color,
  data: setData(idx, 0.1)
})

export default (vueInstance: any, data: Array<IData>) => {
  const canvas = document.createElement('canvas')
  canvas.width = 1040 * 2
  canvas.height = 740 * 2
  const chartPieLocal = echarts.init(canvas)
  const headerData: Array<any> = []

  const setColor = (idx: number, value: number, color: string) => ({
    ...base,
    data: setData(idx, value),
    color,
    name: headerData[idx]
  })

  const getData = (idx: number, value: number, color: string, others?: Array<{value: number; color: string }>) => {
    const data = []
    if (!others || !others.length) {
      data.push(setColor(idx, value - 1, color))
    } else {
      const blocks: Array<{ value: number; color: string; line?: boolean }> = [
        ...others.map((i): { value: number; color: string; line?: boolean } => ({ ...i, line: true })),
        { value, color }
      ].sort((a, b) => a.value - b.value)
      let colorOut = false
      let lastValue = 1
      for (let i = 0; i < blocks.length; i++) {
        const block = blocks[i]
        if (block.line) {
          if (colorOut) {
            data.push(setSpace(idx, block.value - lastValue - 0.1))
          } else {
            data.push(setColor(idx, block.value - lastValue - 0.1, color))
          }
          data.push(setLine(idx, block.color))
        } else {
          colorOut = true
          data.push(setColor(idx, block.value - lastValue, block.color))
        }
        lastValue = block.value
      }
    }
    return data
  }

  const series: Array<any> = []
  let idx = 0
  for (const item of data) {
    series.push(...getData(
      idx++,
      item.value,
      getColor(item.dimention, item.attr),
      item.others
    ))
    headerData.push(getHeaderNode(
      item.name,
      getColor(item.dimention, item.attr),
      item.value,
      item.others
    ))
  }
  const opt = {
    angleAxis: {
      type: 'category',
      data: headerData,
      z: 10
    },
    radiusAxis: {
      min: 0,
      max: 5,
      interval: 0.25,
      axisLabel: {
        show: true,
        fontWeight: 'bold',
        fontSize: 35,
        formatter: (value: number) => +value % 1 === 0 ? (value + 1) : ''
      },
      splitLine: {
        interval: 0.1,
        show: true
      },
      splitArea: {
        interval: 0.1,
        show: true
      }
    },
    polar: {},
    series
  }
  chartPieLocal.setOption(opt)

  chartPieLocal.on('finished', () => {
    vueInstance.pieChart = chartPieLocal.getDataURL({})
    vueInstance.renderPart.pieChart = true
  })
}
