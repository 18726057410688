
import evaluationService from '../../../services/evaluations'

import PdfUtil from '../utils/pdf'
import ImgUtils from '../utils/img'
import ChartPieUtils from '../utils/chart_pie'

import LogoXBase64 from '../base64Files/logoX'

const calculatePages = (vueInstance) => {
  return [
    { text: vueInstance.$t('Reports.Individual.background_1'), sta: 2, end: 2, color: '#BFBFBF' },
    { text: vueInstance.$t('Reports.Individual.background_2'), sta: 3, end: 3, color: '#BFBFBF' },
    { text: vueInstance.$t('Reports.Individual.background_3'), sta: 4, end: 7, color: '#BFBFBF' },
    { text: vueInstance.$t('Reports.Individual.background_4'), sta: 8, end: 8, color: '#BFBFBF' },
    { text: vueInstance.$t('Reports.Individual.background_5'), sta: 9, end: 9, color: '#BFBFBF' },
    { text: vueInstance.$t('Reports.Individual.background_6'), sta: 10, end: 10, color: '#BFBFBF' },
    { text: vueInstance.$t('Reports.Individual.background_7'), sta: 11, end: 11, color: '#49c0a4' },
    { text: vueInstance.$t('Reports.Individual.background_8'), sta: 12, end: 12, color: '#3590d6' },
    { text: vueInstance.$t('Reports.Individual.background_9'), sta: 13, end: 13, color: '#ff4a4a' },
    { text: vueInstance.$t('Reports.Individual.background_10'), sta: 14, end: 14, color: '#BFBFBF' },
    { text: vueInstance.$t('Reports.Individual.background_11'), sta: 15, end: 15, color: '#BFBFBF' },
    { text: vueInstance.$t('Reports.Individual.background_12'), sta: 16, end: 16, color: '#BFBFBF' }
  ]
}
const landscapePages = [11, 12, 13, 14, 15, 16]

export default {
  methods: {
    async $getInitialData () {
      await evaluationService.getOneReportByThreadId(this.thread._id, this.pollId)
        .then((res) => {
          this.evaluation = res.data.evaluation
          this.expectedPolls = res.data.evaluated
          this.completedPolls = res.data.evaluatedCompleted
          this.answersDimention = res.data.answersDimention
          this.strengthsItems = res.data.strengthsItems
          this.improvementItems = res.data.improvementItems
          this.positiveTrends = res.data.positiveTrends
          this.negativeTrends = res.data.negativeTrends
          this.positiveGaps = res.data.positiveGaps
          this.negativeGaps = res.data.negativeGaps
          this.hasPrevious = res.data.hasPrevious
          this.getChartPie()
        })
        .catch((err) => {
          this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
        })
    },
    async $getConfiguration () {
      return {
        pageSize: 'A4',
        info: {
          title: this.$t('Reports.Individual.title'),
          author: 'OCC Solution',
          subject: this.$t('Reports.Individual.title')
        },
        defaultStyle: {
          fontSize: 11,
          font: 'Roboto',
          lineHeight: 1.2
        },
        header: (currentPage) => {
          const resultObj = {
            image: LogoXBase64,
            width: 150,
            height: 60,
            margin: [10, 10, 10, 10]
          }
          if (currentPage === 1) return [{}]
          return [resultObj]
        },
        footer: (currentPage) => {
          return [
            {
              columns: PdfUtil.generateCenteredText(this.$t('Reports.Individual.copyright'), 10),
              color: 'grey'
            },
            {
              margin: [0, 0, 60, 0],
              text: currentPage.toString(),
              alignment: 'right',
              color: 'grey'
            }
          ]
        },
        background: (currentPage) => {
          const temp = calculatePages(this)
          const pageCont = temp.find(t => t.sta <= currentPage && t.end >= currentPage)
          const isLandscapePages = landscapePages.indexOf(currentPage) !== -1
          if (!pageCont) return {}

          const result = {
            image: ImgUtils.writeRotatedText(pageCont.text, pageCont.color, isLandscapePages),
            aligment: 'center',
            absolutePosition: { x: isLandscapePages ? 805 : 568, y: 0 }
          }

          return result
        },
        content: [
          // Portada
          this.$generateCover(),
          // Índice
          this.$generateIndex(),
          // Introducción
          this.$generateIntroduction(),
          // Resultados
          this.$generateResults(),
          // Fortalezas y Oportunidades de Mejora
          this.$generateRank(),
          // Brechas
          this.$generateGaps(),
          // Tendencias
          this.$generateTrend(),
          // Plan
          this.$generatePlan()
        ]
      }
    },
    getChartPie () {
      const data = []
      for (const dimention of Object.keys(this.answersDimention)) {
        for (const attr of Object.keys(this.answersDimention[dimention].attributes)) {
          const base = {
            name: this.evaluation.questionnaire.evaluations[dimention][attr].name.es,
            value: this.round(this.answersDimention[dimention].attributes[attr].score.auto, 2),
            dimention,
            attr,
            others: [{
              color: 'gray',
              value: this.round(this.answersDimention[dimention].attributes[attr].score.team, 2)
            }]
          }
          if (this.hasPrevious) {
            base.others.push({
              color: 'black',
              value: this.round(this.answersDimention[dimention].attributes[attr].score.previous, 2)
            })
          }
          data.push(base)
        }
      }

      ChartPieUtils(this, data)
    }
  }
}
