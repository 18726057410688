
class ImgUtils {
  writeRotatedText (text: string, color: string, isLandscapePages: boolean) {
    const canvas = document.createElement('canvas')
    canvas.width = 38
    canvas.height = 842
    const ctx = canvas.getContext('2d')
    if (!ctx) {
      return
    }

    // Genera color de fondo
    ctx.fillStyle = color
    ctx.fillRect(0, 0, canvas.width * 2, canvas.height * 2)
    ctx.save()

    // Posiciona el elemento al costado derecho de la página
    ctx.translate(38, 845)
    ctx.rotate(-0.5 * Math.PI)

    // Formatea el texto
    ctx.font = '17pt Roboto'
    ctx.fillStyle = 'white'
    ctx.fillText(text.toUpperCase(), isLandscapePages ? 350 : 200, -15)
    ctx.restore()

    return canvas.toDataURL()
  }

  toDataURL (url: string, callback: Function) {
    const xhr = new XMLHttpRequest()
    xhr.open('get', url)
    xhr.responseType = 'blob'

    xhr.onload = function () {
      const fr = new FileReader()

      fr.onload = function () {
        callback(this.result)
      }

      fr.readAsDataURL(xhr.response)
    }

    xhr.send()
  }
}

export default new ImgUtils()
