
export default {
  title: 'Reporte Organizacional POR',
  copyright: 'OCC - Todos los derechos reservados.',
  cover_table_header_1: 'Respuestas esperadas',
  cover_table_header_2: 'Respuestas obtenidas',
  cover_table_header_3: 'Porcentaje de respuestas',
  cover_months: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ],
  background_1: 'Introducción',
  background_2: '¿Para qué es importante el Trabajo en Equipo?',
  background_3: '¿En qué consiste el modelo POR?',
  background_4: '¿Qué contiene el reporte?',
  background_5: 'Recomendaciones',
  background_6: 'Resultados generales',
  background_7: 'Personas',
  background_8: 'Organización',
  background_9: 'Resultados',
  background_10: 'Fortalezas y Oportunidades de Mejora',
  background_11: 'Seguimiento (Tendencias)',
  index_title: 'Contenido del informe',
  index: [
    '01. ¿Para qué es importante el Trabajo en Equipo?',
    '02. ¿En qué consiste el modelo de Trabajo en Equipo POR®?',
    '03. ¿Qué contiene el reporte?',
    '04. Recomendaciones',
    '05. Resultados generales',
    '06. Resultados por conducta: Dimensión Personas',
    '07. Resultados por conducta: Dimensión Organización',
    '08. Resultados por conducta: Dimensión Resultados',
    '09. Fortalezas y Oportunidades de Mejora',
    '10. Seguimiento (Tendencias)',
    '11. Plan de trabajo'
  ],
  index_msg: '“Trabajar en equipo no es una virtud, es una decisión” J. Katzenbach.',
  introduction_title_1: '01. ¿Para qué es importante el Trabajo en Equipo?',
  introduction_title_2: '02. ¿En qué consiste el modelo de Trabajo en Equipo POR®?',
  introduction_title_3: '03. ¿Qué contiene el reporte?',
  introduction_title_4: '04. Recomendaciones',
  introduction_title_1_paragraph_1: 'Pensar en un ser humano aislado y solitario no solo parece una idea aterradora, sino inadecuado en el mundo de hoy. El ser humano de forma natural tiene una tendencia hacia la asociación, por esencia busca pertenecer a un grupo en donde se pueda desarrollar y con el cual sea posible crecer compartiendo afinidades, metas y propósitos.\n\n',
  introduction_title_1_paragraph_2: 'En el mundo de hoy, donde la velocidad del cambio es cada vez mayor y los retos que enfrentamos más complejos, permanecer unidos y orientados hacia objetivos comunes es un factor determinante para el éxito. No obstante, a veces pareciera que permanecer fragmentados aumenta las posibilidades de quedarse con el control y por ende suele entenderse como la “idea de éxito”. Por el contrario, si entendiéramos que en las relaciones no se trata de competir, sino de cooperar, sabríamos que permanecer unidos es fundamental para asegurar la supervivencia y el crecimiento ante el cambio.\n\n',
  introduction_title_1_paragraph_3: 'Aunque suene paradójico, el trabajo en colaboración se vuelve un imperativo en el mundo digital, a la vez que la nueva mentalidad de abundancia y sostenibilidad exige una cultura diferente de ayuda mutua en las organizaciones y en la sociedad en general.\n\n',
  introduction_title_1_paragraph_4: 'Adicionalmente, los nuevos modelos de arquitectura organizacional que promueven las “redarquías” y el trabajo colaborativo, exigen por parte de las personas una mayor capacidad o competencia para trabajar colaborativamente. Los nuevos modelos de liderazgo sinfónico, liderazgo rotativo, trabajo interfuncional y temas afines hacen que desarrollar competencias de trabajo en equipo sea un imperativo.\n\n',
  introduction_title_1_paragraph_5: 'El modelo de Trabajo en Equipo POR® surge como una alternativa para que los líderes y personas entiendan la importancia de las relaciones e interacciones al interior de los equipos. Son las organizaciones quienes más deben promover y asegurar que las personas tengan un buen relacionamiento y trabajen de manera coordinada, orientados hacia la búsqueda de un mismo resultado. Hay una total conexión entre la calidad de las relaciones y los resultados.\n\n',
  introduction_title_1_paragraph_6: 'Para fines de la aplicación del modelo, Trabajo en Equipo está definido como:\n\n',
  introduction_title_1_msg: '“Conjunto de personas íntegras, competentes y comprometidas, mutuamente responsables, que interactúan coordinadamente y se ayudan entre sí para lograr objetivos y propósitos comunes, enmarcados en una misma cultura”.',
  introduction_title_2_paragraph_1: 'El modelo de Trabajo en Equipo POR® está dividido en tres Dimensiones (Personas, Organización y Resultados). Cada Dimensión está compuesta por tres atributos y cada atributo por cinco conductas, así:\n\n',
  introduction_table_1_title: 'PERSONAS',
  introduction_table_1_sub_title_1: 'ATRIBUTO',
  introduction_table_1_sub_title_2: 'CONDUCTA',
  introduction_table_1_attr_1: 'INTEGRIDAD Y TRANSPARENCIA',
  introduction_table_1_attr_1_behavior_1: 'Hacemos siempre lo correcto y actuamos de manera íntegra.',
  introduction_table_1_attr_1_behavior_2: 'Merecemos la confianza por nuestro comportamiento ético y coherente.',
  introduction_table_1_attr_1_behavior_3: 'Mantenemos un lugar seguro, donde podemos ser nosotros mismos.',
  introduction_table_1_attr_1_behavior_4: 'Promovemos la diversidad, inclusión e igualdad de oportunidades.',
  introduction_table_1_attr_1_behavior_5: 'No hablamos mal de los miembros del equipo con terceros.',
  introduction_table_1_attr_2: 'APRENDIZAJE Y COMPETENCIAS',
  introduction_table_1_attr_2_behavior_1: 'Valoramos y promovemos el aprendizaje permanente propio y de los demás.',
  introduction_table_1_attr_2_behavior_2: 'Compartimos buenas prácticas, experiencia y conocimientos con generosidad.',
  introduction_table_1_attr_2_behavior_3: 'Nos esforzamos en conocer el negocio, las tendencias y la competencia.',
  introduction_table_1_attr_2_behavior_4: 'Nos interesamos en conocer el trabajo de los demás y hacer aportes.',
  introduction_table_1_attr_2_behavior_5: 'Pedimos y ofrecemos ayuda cuando se requiere.',
  introduction_table_1_attr_3: 'ACTITUD Y AMBIENTE',
  introduction_table_1_attr_3_behavior_1: 'Somos empáticos y creamos relaciones personales y profesionales sólidas.',
  introduction_table_1_attr_3_behavior_2: 'Superamos las dificultades y mantenemos actitud positiva con alto nivel de compromiso.',
  introduction_table_1_attr_3_behavior_3: 'Celebramos los logros y las victorias tempranas y expresamos gratitud por los aportes de otros.',
  introduction_table_1_attr_3_behavior_4: 'Disfrutamos lo que hacemos y mantenemos un ambiente sano de trabajo.',
  introduction_table_1_attr_3_behavior_5: 'Nos sentimos orgullosos de pertenecer al equipo.',
  introduction_table_2_title: 'ORGANIZACIÓN',
  introduction_table_2_sub_title_1: 'ATRIBUTO',
  introduction_table_2_sub_title_2: 'CONDUCTA',
  introduction_table_2_attr_1: 'CULTURA Y RELACIONES',
  introduction_table_2_attr_1_behavior_1: 'Demostramos conductas acordes con nuestros valores; vivimos la cultura de la Organización.',
  introduction_table_2_attr_1_behavior_2: 'Definimos y respetamos unas reglas de oro que guían nuestros comportamientos.',
  introduction_table_2_attr_1_behavior_3: 'Creamos y mantenemos relaciones basadas en la confianza.',
  introduction_table_2_attr_1_behavior_4: 'Para nosotros la cooperación prima sobre la competencia.',
  introduction_table_2_attr_1_behavior_5: 'Valoramos las diferencias y manejamos adecuadamente los conflictos.',
  introduction_table_2_attr_2: 'LIDERAZGO Y COORDINACIÓN',
  introduction_table_2_attr_2_behavior_1: 'Mantenemos una comunicación oportuna, efectiva y transparente.',
  introduction_table_2_attr_2_behavior_2: 'Promovemos el liderazgo colectivo y aceptamos el liderazgo de otros.',
  introduction_table_2_attr_2_behavior_3: 'Los roles y responsabilidades de todos en el equipo son claros.',
  introduction_table_2_attr_2_behavior_4: 'Trabajamos de manera coordinada y armónica.',
  introduction_table_2_attr_2_behavior_5: 'Somos abiertos al cambio, promovemos la innovación y las nuevas iniciativas.',
  introduction_table_2_attr_3: 'EFECTIVIDAD Y EXCELENCIA',
  introduction_table_2_attr_3_behavior_1: 'Hacemos nuestro trabajo con calidad y nos gusta la excelencia.',
  introduction_table_2_attr_3_behavior_2: 'Buscamos soluciones y no culpables. Asumimos la responsabilidad en vez de buscar excusas.',
  introduction_table_2_attr_3_behavior_3: 'Mantenemos una metodología adecuada de reuniones.',
  introduction_table_2_attr_3_behavior_4: 'Manejamos el tiempo de manera efectiva.',
  introduction_table_2_attr_3_behavior_5: 'Nos gusta la mejora continua y buscamos la efectividad de los procesos.',
  introduction_table_3_title: 'RESULTADOS',
  introduction_table_3_sub_title_1: 'ATRIBUTO',
  introduction_table_3_sub_title_2: 'CONDUCTA',
  introduction_table_3_attr_1: 'ESTRATEGIA Y EJECUCIÓN',
  introduction_table_3_attr_1_behavior_1: 'Tenemos metas y responsabilidades compartidas.',
  introduction_table_3_attr_1_behavior_2: 'Las metas son comunicadas y claras para todos.',
  introduction_table_3_attr_1_behavior_3: 'Tenemos un propósito común y una visión que nos inspira.',
  introduction_table_3_attr_1_behavior_4: 'Definimos y revisamos colectivamente los objetivos y estrategias.',
  introduction_table_3_attr_1_behavior_5: 'Hacemos seguimiento continuo de indicadores y actuamos proactivamente.',
  introduction_table_3_attr_2: 'RELACIONES Y COLABORACIÓN',
  introduction_table_3_attr_2_behavior_1: 'Somos conscientes del impacto de las buenas relaciones en los resultados.',
  introduction_table_3_attr_2_behavior_2: 'Entendemos que el reto de uno es un reto de todos y nos ayudamos mutuamente.',
  introduction_table_3_attr_2_behavior_3: 'Respondemos colectiva e individualmente por los resultados.',
  introduction_table_3_attr_2_behavior_4: 'Nos damos apoyo y permanecemos como equipo en las buenas y en las malas.',
  introduction_table_3_attr_2_behavior_5: 'Buscamos siempre la mejor opción para el equipo, sin egos ni protagonismo.',
  introduction_table_3_attr_3: 'DECISIONES Y PRIORIDADES',
  introduction_table_3_attr_3_behavior_1: 'Tenemos procesos efectivos de toma de decisiones con adecuada autonomía.',
  introduction_table_3_attr_3_behavior_2: 'Las decisiones tomadas son apoyadas siempre por todos con alto nivel de compromiso.',
  introduction_table_3_attr_3_behavior_3: 'A la hora de tomar decisiones tenemos en cuenta nuestra estrategia y cultura.',
  introduction_table_3_attr_3_behavior_4: 'Las prioridades son claras y alineadas con la estrategia.',
  introduction_table_3_attr_3_behavior_5: 'Cuando ocurren cambios, aseguramos un proceso rápido de alineación.',
  introduction_title_3_paragraph_1: 'La evaluación de Trabajo en Equipo POR® es una herramienta de valoración que le permite a cada integrante dar su percepción de como se viven las conductas de un equipo de alto desempeño en su equipo de trabajo.\n\n',
  introduction_title_3_paragraph_2: 'En el reporte, cada persona puede contrastar su percepción con el promedio de la percepción de quienes llenaron la encuesta.\n\n',
  introduction_title_3_paragraph_3: 'El propósito de la herramienta de valoración de Trabajo en Equipo POR® es dar, tanto a cada integrante como al equipo en su totalidad, información pertinente para definir planes de acción que los lleven a tener un alto desempeño.\n\n',
  introduction_title_3_paragraph_4: 'Los alcances de la evaluación de Trabajo en Equipo POR® son los siguientes:\n',
  introduction_title_3_ul: [
    'Elevar consciencia de las competencias de Trabajo en Equipo.',
    'Resaltar las fortalezas para reforzarlas y de igual forma las debilidades para convertirlas en oportunidades de mejora.',
    'Motivar a los integrantes de un equipo a generar y fortalecer relaciones de confianza.',
    'Propiciar conversaciones y espacios de interacción para potencializar la colaboración y la inteligencia colectiva.',
    'Incentivar la alineación entre los integrantes de un equipo por medio de la creación compartida de objetivos y propósitos comunes.',
    'Definir planes de acción, individuales y colectivos, para lograr resultados sobresalientes y sostenibles como equipo de alto desempeño.'
  ],
  introduction_title_3_sub_1: 'Resultados por Dimensión, Atributo y Conductas:',
  introduction_title_3_sub_1_paragraph: 'La información que se describe en esta sección se presenta para cada una de las Dimensiones del modelo POR®: Personas, Organización y Resultados, por Atributo y por Conducta. En cada tabla puede ver su percepción, el promedio de las percepciones del equipo y la tendencia, es caso de haber posteriores mediciones.',
  introduction_title_3_sub_2: 'Fortalezas, Oportunidades de Mejora y Seguimiento',
  introduction_title_3_sub_2_paragraph_1: 'Con respecto a las conductas, se presenta una tabla que muestra los seis puntajes más altos (para identificar las fortalezas) y los seis puntajes más bajos (para identificar oportunidades de mejora) de la Organización.\n\n',
  introduction_title_3_sub_2_paragraph_2: 'Finalmente, aparece una tabla donde se resaltan las seis conductas que mejoraron más en el promedio y las seis que porcentualmente más empeoraron. Este análisis no aplica para la primera medición.',
  introduction_title_4_paragraph_1: 'La encuesta sobre el Modelo de Trabajo en Equipo POR® refleja las percepciones de las personas y sirve como base para el desarrollo de un trabajo continuo de construcción de equipo. Por su naturaleza no se puede determinar que la realidad del equipo sea exactamente como lo refleja la encuesta, sino que es una ayuda para entender la forma en que las personas están percibiendo su relacionamiento. Es recomendable hacer seguimiento sobre los resultados que surjan y llevar a cabo los planes de acción definidos.\n\n',
  introduction_title_4_paragraph_2: 'Mida con frecuencia, dependiendo el nivel de efectividad del equipo, puede ser entre 4 a 6 meses. En cada medición, definan colectivamente un plan de acción y unos compromisos. Definan si no tienen, o revisen si existen, unas reglas de oro o pautas de conductas pertinentes que contribuyan con el desempeño del equipo.\n\n',
  introduction_title_4_paragraph_3: 'Enfóquense en dos a tres atributos donde deben mejorar y precisen los planes de acción lo más posible, revisando la información en cuanto a conductas. Si es posible, trate de trabajar en las tres dimensiones.\n\n',
  introduction_title_4_paragraph_4: 'Tengan reunión de retroalimentación permanente, uno a uno y todos en conjunto. Revisen el plan de acción y los acuerdos definidos. Asegúrense de mantenerse todos comprometidos con el proceso y celebren las victorias tempranas.\n\n',
  introduction_title_4_paragraph_5: 'Asegúrense de mantener un lugar seguro donde se valoren las diferencias, se pueda crecer del conflicto, se permita la inclusión, se desarrollen conversaciones poderosas y se promueva la confianza.\n\n',
  introduction_title_4_paragraph_6: 'Usted puede ser parte de un equipo o liderarlo. Asegúrese que este proceso se da en las dos dimensiones por separado, es decir, puede haber realidades distintas en un equipo en el cual usted es un miembro de otro donde Usted lidera.',
  introduction_title_4_msg: 'GRACIAS POR SU INTERÉS Y ESPERAMOS QUE ESTA INFORMACIÓN SEA DE GRAN UTILIDAD PARA USTED Y LA ORGANIZACIÓN.',
  results_title_1: '5. Resultados generales',
  result_legend_table_1: 'Rango de datos (min-max)',
  result_legend_table_2: 'Equipo',
  result_legend_table_3: 'Fortalezas',
  result_legend_table_4: 'Oportunidades de mejora',
  results_title_1_table_header_1: 'Equipo',
  results_title_1_table_header_2: 'Tendencia',
  results_info_1: 'El puntaje del equipo es el promedio de como cada uno de ustedes valora al equipo de trabajo.',
  results_info_2: 'Esta gráfica muestra los resultados generales de acuerdo con el promedio de las valoraciones del equipo.',
  results_title_2: '06. Resultados por conducta: Dimensión Personas',
  results_info_3: 'A continuación se presenta, para cada conducta el puntaje promedio obtenido por el Equipo y el rango de respuestas. Teniendo en cuenta el resultado de las 3 Dimensiones, se señalan en color azul los 6 puntajes más altos (Fortalezas) y en naranja los 6 más bajos (Oportunidades de Mejora). La columna Tendencia muestra si hubo cambio positivo o negativo en el promedio del equipo de acuerdo con la anterior medición (no aplica para una primera aplicación de la encuesta).',
  results_title_3: '07. Resultados por conducta: Dimensión Organización',
  results_info_4: 'A continuación se presenta, para cada conducta el puntaje promedio obtenido por el Equipo y el rango de respuestas. Teniendo en cuenta el resultado de las 3 Dimensiones, se señalan en color azul los 6 puntajes más altos (Fortalezas) y en naranja los 6 más bajos (Oportunidades de Mejora). La columna Tendencia muestra si hubo cambio positivo o negativo en el promedio del equipo de acuerdo con la anterior medición (no aplica para una primera aplicación de la encuesta).',
  results_title_4: '08. Resultados por conducta: Dimensión Resultados',
  results_info_5: 'A continuación se presenta, para cada conducta el puntaje promedio obtenido por el Equipo y el rango de respuestas. Teniendo en cuenta el resultado de las 3 Dimensiones, se señalan en color azul los 6 puntajes más altos (Fortalezas) y en naranja los 6 más bajos (Oportunidades de Mejora). La columna Tendencia muestra si hubo cambio positivo o negativo en el promedio del equipo de acuerdo con la anterior medición (no aplica para una primera aplicación de la encuesta).',
  results_title_2_table_header_1: 'Equipo',
  results_title_2_table_header_2: 'Tendencia',
  results_title_3_table_header_1: 'Equipo',
  results_title_3_table_header_2: 'Tendencia',
  results_title_4_table_header_1: 'Equipo',
  results_title_4_table_header_2: 'Tendencia',
  rank_title: '09. Fortalezas y Oportunidades de Mejora',
  rank_title_info: 'Estas son las conductas con los 6 puntajes más altos (Fortalezas) y los 6 más bajos (Oportunidades de Mejora) según el promedio de las valoraciones del equipo.',
  rank_table_1_header: 'Fortalezas',
  rank_table_1_sub_header_1: 'DIMENSIÓN',
  rank_table_1_sub_header_2: 'ATRIBUTO',
  rank_table_1_sub_header_3: 'CONDUCTA',
  rank_table_1_sub_header_4: 'EQUIPO',
  rank_table_1_sub_header_5: 'TENDENCIA',
  rank_table_2_header: 'Oportunidades de Mejora',
  rank_table_2_sub_header_1: 'DIMENSIÓN',
  rank_table_2_sub_header_2: 'ATRIBUTO',
  rank_table_2_sub_header_3: 'CONDUCTA',
  rank_table_2_sub_header_4: 'EQUIPO',
  rank_table_2_sub_header_5: 'TENDENCIA',
  trend_title: '10. Seguimiento (Tendencias)',
  trend_info: 'Las siguientes tablas solo aplica cuando se hace una medición de seguimiento. En esta se presentan los 6 mayores cambios o saltos Positivos del Equipo (donde el promedio actual es mayor al anterior) y Negativos (donde el promedio actual disminuye con respecto al anterior).',
  trend_msg: 'Disponible a partir de la segunda medición',
  trend_table_1_title: 'Tendencias Positivas',
  trend_table_1_header_1: 'DIMENSIÓN',
  trend_table_1_header_2: 'ATRIBUTO',
  trend_table_1_header_3: 'CONDUCTA',
  trend_table_1_header_4: 'ACTUAL',
  trend_table_1_header_5: 'ANTERIOR',
  trend_table_1_header_6: 'TENDENCIA',
  trend_table_2_title: 'Tendencias Negativas',
  trend_table_2_header_1: 'DIMENSIÓN',
  trend_table_2_header_2: 'ATRIBUTO',
  trend_table_2_header_3: 'CONDUCTA',
  trend_table_2_header_4: 'ACTUAL',
  trend_table_2_header_5: 'ANTERIOR',
  trend_table_2_header_6: 'TENDENCIA',
  plan_title: 'PLAN DE ACCIÓN COLECTIVO – TRABAJO EN EQUIPO – MODELO POR',
  plan_msg: 'Basados en la información del reporte y el conocimiento de nosotros mismos, estas son las acciones y compromisos para mejorar nuestro trabajo colaborativo',
  plan_table_1_col_1: 'NOMBRE DEL EQUIPO',
  plan_table_1_col_2: 'FECHA',
  plan_table_2_header_1: 'ACCIONES – ¿Qué vamos a comenzar a hacer?',
  plan_table_2_header_2: 'RESULTADO ESPERADO',
  plan_table_2_header_3: 'SEGUIMIENTO',
  plan_table_3_header_1: 'RENUNCIAS – ¿Qué vamos a dejar de hacer?',
  plan_table_3_header_2: 'RESULTADO ESPERADO',
  plan_table_3_header_3: 'SEGUIMIENTO',
  plan_table_4_header_1: 'NUESTROS COMPROMISOS – Conductas',
  plan_table_4_header_2: 'RESULTADO ESPERADO',
  plan_table_4_header_3: 'SEGUIMIENTO'
}
