var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"12","md":"3"}},[_c('h4',{staticClass:"display-1"},[_vm._v(" "+_vm._s(_vm.$t('Views.Evaluations.list.title')))])]),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","md":"9"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"pa-0 text-right",attrs:{"cols":"12","md":"8"}},[_c('v-btn',{attrs:{"outlined":"","large":"","color":"primary"},on:{"click":function($event){_vm.showModal = !_vm.showModal}}},[_vm._v(" "+_vm._s(_vm.$t('Views.Evaluations.list.generic_link_btn'))+" ")])],1),_c('v-col',{staticClass:"pa-0 text-right pt-5 pt-md-0",attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"large":"","color":"primary","to":"/evaluations/create"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("fa-plus-circle")]),_vm._v(" "+_vm._s(_vm.$t('Views.Evaluations.list.btn_create'))+" ")],1)],1)],1)],1)],1),_c('v-card',{staticClass:"mt-5 px-4"},[_c('v-row',[_c('v-col',[_c('v-layout',{staticClass:"ml-3",attrs:{"row":"","wrap":""}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-autocomplete',{attrs:{"label":_vm.$t('Views.Evaluations.list.input_filter_by'),"items":_vm.filters,"prepend-inner-icon":"mdi-filter-outline"},on:{"change":_vm.filterItems}})],1)],1),_c('x-data-table',{attrs:{"headers":_vm.headers,"parent-fetch-data":_vm.getEvaluations,"options":_vm.options,"no-data":"Views.Evaluations.list.table_no_data"},scopedSlots:_vm._u([{key:"structure",fn:function(prop){return [_c('td',{staticClass:"text-center",staticStyle:{"vertical-align":"middle"}},[_vm._v(_vm._s(prop.item.displayName ? prop.item.displayName : prop.item.name))]),_c('td',{staticClass:"text-center",staticStyle:{"vertical-align":"middle"}},[_c('v-tooltip',{attrs:{"top":"","color":"blue","disabled":prop.item.status !== 'creating'},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-chip',_vm._g({staticClass:"status-chip",attrs:{"outlined":"","label":"","color":_vm.getColor(prop.item.status)}},on),[_vm._v(" "+_vm._s(_vm.$t(("Views.Evaluations.list.status_" + (prop.item.status))))+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Views.Evaluations.list.may_take_while')))])])],1),_c('td',{staticClass:"text-center",staticStyle:{"vertical-align":"middle"}},[_vm._v(_vm._s(_vm._f("date")(prop.item.deliveredAt,{date: true, hour: false}))+" hasta "+_vm._s(_vm._f("date")(prop.item.validUntil,{date: true, hour: false}))+" ")]),_c('td',{staticClass:"text-center px-0",staticStyle:{"vertical-align":"middle"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([(prop.item.status !== 'completed')?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":("evaluations/" + (prop.item.slug) + "/edit"),"text":"","icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("edit")])],1)]}}:null],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Views.Evaluations.list.btn_edit')))])]),_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":("/evaluations/" + (prop.item.slug) + "/details"),"text":"","icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fa-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Views.Evaluations.list.btn_details')))])]),(prop.item.status === 'completed')?_c('v-tooltip',{attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":("/evaluations/reports/" + (prop.item._id)),"icon":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-chart-bar-stacked")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Views.Evaluations.list.btn_report')))])]):_vm._e()],1)]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"width":"650px","persistent":""},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('Views.Evaluations.list.modal_copy_invitation_url_title'))+" ")])]),_c('v-card-subtitle',[_c('p',{staticClass:"mt-4 mb-0 text-justify body-2"},[_vm._v(" "+_vm._s(_vm.$t('Views.Evaluations.list.modal_copy_invitation_url'))+" ")])]),_c('v-card-text',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"11"}},[_c('v-text-field',{attrs:{"label":_vm.$t('Views.Evaluations.list.modal_invitation_url'),"readonly":true,"outlined":"","id":"tokenUrl"},model:{value:(_vm.tokenUrl),callback:function ($$v) {_vm.tokenUrl=$$v},expression:"tokenUrl"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-2",attrs:{"icon":""},on:{"click":_vm.copyUrl}},on),[_c('v-icon',[_vm._v("file_copy")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Views.Evaluations.list.modal_link')))])])],1)],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"block":"","large":""},on:{"click":_vm.closeModal}},[_vm._v(_vm._s(_vm.$t('Views.Evaluations.list.modal_btn_close')))])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }