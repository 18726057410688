
import PdfUtil from '../utils/pdf'

import StampBase64 from '../base64Files/stamp'

const getBaseHeader = (text, white, colSpan) => {
  return {
    bold: true,
    fontSize: 13,
    color: white ? 'white' : 'black',
    text,
    colSpan: colSpan || 1,
    alignment: 'center'
  }
}

const getIntroductionTable = (vueInstance, base, color1) => {
  const table = {
    widths: ['30%', '70%'],
    headerRows: 2,
    body: [
      [getBaseHeader(vueInstance.$t(`Reports.Organizational.${base}title`), true, 2), {}],
      [
        getBaseHeader(vueInstance.$t(`Reports.Organizational.${base}sub_title_1`), true),
        getBaseHeader(vueInstance.$t(`Reports.Organizational.${base}sub_title_2`))
      ]
    ]
  }
  for (let i = 1; i <= 3; i++) {
    table.body.push([
      { rowSpan: 5, text: '\n\n\n' + vueInstance.$t(`Reports.Organizational.${base}attr_${i}`), alignment: 'center', color: 'white', bold: true },
      vueInstance.$t(`Reports.Organizational.${base}attr_${i}_behavior_1`)
    ])
    for (let j = 2; j <= 5; j++) {
      table.body.push(['', vueInstance.$t(`Reports.Organizational.${base}attr_${i}_behavior_${j}`)])
    }
  }
  return {
    table,
    fontSize: 10,
    layout: {
      fillColor: (rowIndex, node, columnIndex) => {
        if (rowIndex === 0 || columnIndex === 0) {
          return color1
        }
        return (rowIndex % 2 === 0) ? '#dedada' : '#eeeeee'
      }
    }
  }
}

export default {
  methods: {
    $generateIntroduction () {
      return [
        PdfUtil.generateTitle(this.$t('Reports.Organizational.introduction_title_1'), 'before'),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_1_paragraph_1')),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_1_paragraph_2')),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_1_paragraph_3')),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_1_paragraph_4')),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_1_paragraph_5')),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_1_paragraph_6')),
        {
          columns: PdfUtil.generateCenteredText(this.$t('Reports.Organizational.introduction_title_1_msg'), 14, true),
          margin: [0, 20, 0, 0]
        },
        PdfUtil.generateTitle(this.$t('Reports.Organizational.introduction_title_2'), 'before'),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_2_paragraph_1')),
        {
          image: StampBase64,
          width: 450,
          height: 450,
          alignment: 'center',
          margin: [30, -4, 0, -20]
        },
        PdfUtil.generateTitle(this.$t('Reports.Organizational.introduction_title_2'), 'before'),
        getIntroductionTable(this, 'introduction_table_1_', '#49c0a4'),
        PdfUtil.generateTitle(this.$t('Reports.Organizational.introduction_title_2'), 'before'),
        getIntroductionTable(this, 'introduction_table_2_', '#3590d6'),
        PdfUtil.generateTitle(this.$t('Reports.Organizational.introduction_title_2'), 'before'),
        getIntroductionTable(this, 'introduction_table_3_', '#ff4a4a'),
        PdfUtil.generateTitle(this.$t('Reports.Organizational.introduction_title_3'), 'before'),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_3_paragraph_1')),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_3_paragraph_2')),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_3_paragraph_3')),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_3_paragraph_4')),
        { ul: this.$t('Reports.Organizational.introduction_title_3_ul') },
        PdfUtil.generateSubTitle(this.$t('Reports.Organizational.introduction_title_3_sub_1')),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_3_sub_1_paragraph')),
        PdfUtil.generateSubTitle(this.$t('Reports.Organizational.introduction_title_3_sub_2')),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_3_sub_2_paragraph_1')),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_3_sub_2_paragraph_2')),
        PdfUtil.generateTitle(this.$t('Reports.Organizational.introduction_title_4'), 'before'),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_4_paragraph_1')),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_4_paragraph_2')),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_4_paragraph_3')),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_4_paragraph_4')),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_4_paragraph_5')),
        PdfUtil.generateParagraph(this.$t('Reports.Organizational.introduction_title_4_paragraph_6')),
        {
          columns: PdfUtil.generateCenteredText(this.$t('Reports.Organizational.introduction_title_4_msg'), 14, true),
          margin: [0, 20, 0, 0]
        }
      ]
    }
  }
}
