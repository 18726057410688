
<template>
  <div style="display:inline;">
    <v-btn large
      v-if="!thread._id"
      color="primary"
      :disabled="lockPdfButton || !disableButton"
      :loading="lockPdfButton"
      @click="getPreviousAssessments"
    >
      <v-icon>mdi-file-pdf</v-icon>
      {{ $t('Views.Report.threadOrganizational.input_generate_report') }}
    </v-btn>
    <x-thread-organizational-report-exec
      v-else
      :thread="thread"
      :poll-id="pollId"
    ></x-thread-organizational-report-exec>

    <!-- Previous Polls Modal -->
    <x-previous-polls-dialog
      :show="modalPreviousAssessment"
      :previousAssessments="previousAssessments"
      @continue="setSelectedPrevious"
      @skip="showModalConfirm = true"
    ></x-previous-polls-dialog>

    <!-- Spend confirmation modal -->
    <x-confirm-spend-dialog
      :confirmText="$t('Views.Report.threadOrganizational.modal_confirm_report_title')"
      :costText="$t('Views.Report.threadOrganizational.modal_workshop_cost')"
      :showModalConfirm="showModalConfirm"
      :balance="balance"
      :price="price"
      :noBalanceResponse="noBalanceResponse"
      :disableButtonModal="disableButton"
      @result="verifySpend"
      @update="checkBalance">
    </x-confirm-spend-dialog>
  </div>
</template>

<script>
import Vue from 'vue'

import evaluationService from '../../services/evaluations'

import XPreviousPollsDialog from '@/components/dialogs/previous-polls'
import XThreadOrganizationalReportExec from './thread_organizational_report_exec'

export default Vue.extend({
  name: 'thread-organizational-report',
  components: {
    XPreviousPollsDialog,
    XThreadOrganizationalReportExec
  },
  props: {
    pollId: String,
    disableButton: Boolean,
    thread: Object
  },
  data () {
    return {
      modalPreviousAssessment: false,
      previousAssessments: [],
      selectedPreviousAssessment: undefined,
      lockPdfButton: false,
      showModalConfirm: false,
      disableButtonModal: true,
      noBalanceResponse: false,
      operationsLink: '',
      balance: 0,
      price: 0
    }
  },
  watch: {
    showModalConfirm (val) {
      if (val) {
        this.modalPreviousAssessment = false
      } else {
        this.selectedPreviousAssessment = undefined
        this.lockPdfButton = false
      }
    },
    disableButton () {
      this.disableButtonModal = this.disableButton
    }
  },
  mounted () {
    this.checkBalance()
  },
  methods: {
    runOpenReport () {
      this.$store.dispatch('loading/show')
      return evaluationService.openReportOneById(this.pollId, this.selectedPreviousAssessment)
        .then(() => {
          this.$store.dispatch('alert/success', this.$t('Views.Report.threadOrganizational.opetaion_init'))
          this.$store.dispatch('loading/hide')
          this.showModalConfirm = false
          setTimeout(this.refreshPage, 2000)
        })
        .catch((err) => {
          if (err.code === 'suite-fail/evaluation/spend-fail') {
            this.noBalanceResponse = true
            this.disableButtonModal = true
            this.$store.dispatch('alert/error', this.$t('errors.no_balance'))
          } else {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
          this.$store.dispatch('loading/hide')
          this.lockPdfButton = false
        })
    },
    checkBalance () {
      return evaluationService.checkBalance('roop')
        .then((res) => {
          this.balance = res.balance
          this.price = res.productService
          this.$store.dispatch('loading/hide')
        })
    },
    verifySpend ($event) {
      return $event ? this.runOpenReport() : (this.showModalConfirm = false)
    },
    getPreviousAssessments () {
      if (this.previousAssessments.length > 0) {
        this.modalPreviousAssessment = true
      } else {
        this.lockPdfButton = true
        return evaluationService.getAssessmentsPreviousToCurrent(this.pollId)
          .then((res) => {
            if (res.length) {
              this.previousAssessments = res.map(ev => {
                return {
                  text: ev.name,
                  value: ev._id
                }
              })
              this.modalPreviousAssessment = true
            } else {
              this.showModalConfirm = true
            }
          })
          .catch((err) => {
            console.log(err)
            this.showModalConfirm = true
          })
      }
    },
    setSelectedPrevious (ref) {
      this.selectedPreviousAssessment = ref
      this.showModalConfirm = true
    },
    refreshPage () {
      window.location.reload()
    }
  }
})
</script>
