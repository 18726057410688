
export default {
  list: {
    title: 'Cuestionarios',
    btn_create: 'Agregar',
    btn_edit: 'Editar cuestionario',
    btn_settings: 'Configuración de cuestionario',
    btn_trash: 'Borrar',
    table_name: 'Nombre',
    table_last_edition: 'Ultima edición',
    table_status: 'Estado',
    table_actions: 'Acciones',
    modal_enable: '¿Desea habilitar el cuestionario?',
    modal_disable: '¿Desea inhabilitar el cuestionario?',
    modal_delete: '¿Desea eliminar el cuestionario?',
    modal_btn_trash: 'Borrar'
  },
  create: {
    title: 'Crear cuestionario',
    input_name: 'Nombre',
    input_base: 'Cuestionario base',
    input_asignation_type: 'Tipo de asignación',
    input_assign_to: 'Asignar a',
    input_option_customer: 'Cliente',
    input_option_enterprise: 'Empresa',
    input_option_sector: 'Sector',
    btn_cancel: 'Cancelar',
    btn_save: 'Guardar',
    created_succesfully: 'Cuestionario creado correctamente',
    created_error: 'Error en la creación del cuestionario'
  },
  editQuestionnaire: {
    title: 'Editar cuestionario',
    input_name: 'Nombre',
    input_asignation_type: 'Tipo de asignación',
    input_assign_to: 'Asignar a',
    input_option_customer: 'Cliente',
    input_option_enterprise: 'Empresa',
    input_option_sector: 'Sector',
    btn_cancel: 'Cancelar',
    btn_update: 'Actualizar',
    updated_succesfully: 'Cuestionario actualizado correctamente',
    updated_error: 'Error actualizando el cuestionario'
  },
  edit: {
    title: 'Editar preguntas del cuestionario',
    btn_back: 'Atras',
    input_attribute: 'Atributo',
    input_question: 'Pregunta',
    input_question_autoEvaluation: 'Pregunta auto evaluación (1ra persona)',
    input_question_generalEvaluation: 'Pregunta equipo (3ra persona)',
    tooltip_info: 'Debe presionar ENTER para editar',
    edited_succesfully: 'Cuestionario editado correctamente',
    edited_error: 'Error en la edición del cuestionario'
  }
}
