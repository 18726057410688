
<template>
  <div style="display:inline;">
    <v-btn disabled large
      v-if="pollStatus !== 'completed'"
      color="red"
      :loading="lockPdfButton"
    >
      <v-icon>mdi-file-cancel</v-icon>
      {{ $t('Views.Report.threadIndividualReport.input_no_participation') }}
    </v-btn>
    <v-btn large
      v-else-if="!thread || !thread._id"
      color="primary"
      :loading="lockPdfButton"
      :disabled="lockPdfButton || !disableButton"
      @click="getPreviousAssessments"
    >
      <v-icon>mdi-file-pdf</v-icon>
      {{ $t('Views.Report.threadIndividualReport.input_generate_report') }}
    </v-btn>
    <x-thread-individual-report-exec
      v-else
      :thread="thread"
      :poll-id="pollId"
      :evaluated-name="evaluatedName"
    ></x-thread-individual-report-exec>

    <!-- Previous Polls Modal -->
    <x-previous-polls-dialog
      :show="modalPreviousAssessment"
      :previousAssessments="previousAssessments"
      @continue="setSelectedPrevious"
      @skip="runOpenReport()"
    ></x-previous-polls-dialog>
  </div>
</template>

<script>
import Vue from 'vue'

import evaluationService from '../../services/evaluations'

import XPreviousPollsDialog from '@/components/dialogs/previous-polls'
import XThreadIndividualReportExec from './thread_individual_report_exec'

export default Vue.extend({
  name: 'thread-individual-report',
  components: {
    XPreviousPollsDialog,
    XThreadIndividualReportExec
  },
  props: {
    pollId: String,
    pollStatus: String,
    disableButton: Boolean,
    evaluatedId: Number,
    evaluatedName: String,
    thread: Object
  },
  data () {
    return {
      modalPreviousAssessment: false,
      previousAssessments: [],
      selectedPreviousAssessment: undefined,
      lockPdfButton: false,
      disableButtonModal: true
    }
  },
  watch: {
    disableButton () {
      this.disableButtonModal = this.disableButton
    }
  },
  methods: {
    runOpenReport () {
      this.modalPreviousAssessment = false
      this.$store.dispatch('loading/show')
      this.lockPdfButton = true
      return evaluationService.openReportIndividualOneById(
        this.pollId,
        this.evaluatedId,
        this.selectedPreviousAssessment
      )
        .then(() => {
          this.$store.dispatch('alert/success', this.$t('Views.Report.threadIndividualReport.opetaion_init'))
          this.$store.dispatch('loading/hide')
          setTimeout(this.refreshPage, 2000)
        })
        .catch((err) => {
          if (err.code === 'suite-fail/evaluation/spend-fail') {
            this.noBalanceResponse = true
            this.disableButtonModal = true
            this.$store.dispatch('alert/error', this.$t('errors.no_balance'))
          } else {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          }
          this.$store.dispatch('loading/hide')
          this.lockPdfButton = false
        })
    },
    getPreviousAssessments () {
      if (this.previousAssessments.length > 0) {
        this.modalPreviousAssessment = true
      } else {
        // this.lockPdfButton = true
        return evaluationService.getEvaluatedAssessmentsPreviousTo(this.pollId, this.evaluatedId)
          .then((res) => {
            if (res.length) {
              this.previousAssessments = res.map(ev => {
                return {
                  text: ev.name,
                  value: ev._id
                }
              })
              this.modalPreviousAssessment = true
            } else {
              this.runOpenReport()
            }
          })
          .catch((err) => {
            this.$store.dispatch('alert/error', this.$t(`errors.${err.code}`))
          })
      }
    },
    setSelectedPrevious (ref) {
      this.selectedPreviousAssessment = ref
      this.runOpenReport()
    },
    refreshPage () {
      window.location.reload()
    }
  }
})
</script>
