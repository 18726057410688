
export default {
  rgb: (): {[key: string]: {[key: string]: string}} => ({
    persons: {
      color: 'rgba(73, 192, 164, 0.8)',
      attr_1: 'rgba(84, 204, 199, 0.8)',
      attr_2: 'rgba(103, 171, 161, 0.8)',
      attr_3: 'rgba(94, 171, 146, 0.8)'
    },
    organization: {
      color: 'rgba(54, 145, 214, 0.8)',
      attr_1: 'rgba(54, 145, 214, 0.8)',
      attr_2: 'rgba(91, 140, 194, 0.8)',
      attr_3: 'rgba(81, 155, 194, 0.8)'
    },
    results: {
      color: 'rgba(255, 74, 74, 0.8)',
      attr_1: 'rgba(255, 98, 82, 0.8)',
      attr_2: 'rgba(235, 125, 120, 0.8)',
      attr_3: 'rgba(235, 109, 130, 0.8)'
    }
  }),
  hx: (): {[key: string]: {[key: string]: string}} => ({
    persons: {
      color: '#49C0A4',
      attr_1: '#54CCC7',
      attr_2: '#67ABA1',
      attr_3: '#5EAB92'
    },
    organization: {
      color: '#3691D6',
      attr_1: '#407FE3',
      attr_2: '#5B8CC2',
      attr_3: '#519BC2'
    },
    results: {
      color: '#ff4a4a',
      attr_1: '#FF6252',
      attr_2: '#EB7D78',
      attr_3: '#EB6D82'
    }
  }),
  results: () => ({
    range: '#a5a5a5',
    rangeStrengths: '#07e0db',
    rangeImprovement: '#ed7c31'
  })
}
