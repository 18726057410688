
import PdfUtil from '../utils/pdf'

export default {
  methods: {
    $generateIndex () {
      return [
        PdfUtil.generateTitle(this.$t('Reports.Organizational.index_title'), 'before'),
        {
          columns: [
            {
              width: 'auto',
              stack: this.$t('Reports.Organizational.index'),
              margin: [15, 0, 0, 0],
              lineHeight: 2,
              fontSize: 13
            },
            { width: '*', text: '' },
            {
              width: '14%',
              text: '3\n4\n8\n9\n10\n11\n12\n13\n14\n15\n16',
              lineHeight: 2,
              fontSize: 13
            }
          ]
        },
        {
          columns: PdfUtil.generateCenteredText(this.$t('Reports.Organizational.index_msg'), 14, true),
          margin: [0, 20, 0, 0]
        }
      ]
    }
  }
}
