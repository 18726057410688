
import PdfUtil from '../utils/pdf'
import ColorUtils from '../utils/color'

import Diamond64 from '../base64Files/diamond'
import XDiamond64 from '../base64Files/x-diamond'
import X64 from '../base64Files/x'

const colors = ColorUtils.hx()

const getTrend = (hasPrevious, score, key, round) => {
  return hasPrevious ? { text: round(score[key] - score.previous, 2), alignment: 'right', margin: [0, 0, 20, 0] } : { text: '--', alignment: 'center' }
}

const getScore = (score, round) => {
  return { text: round(score, 2), alignment: 'center' }
}

const resultLegend = (idx) => {
  const resultColors = ColorUtils.results()
  switch (idx) {
    case 1: return resultColors.range
    case 9: return resultColors.rangeStrengths
    case 13: return resultColors.rangeImprovement
    default: return '#fff'
  }
}

const getBaseHeader = (text) => {
  return {
    bold: true,
    fontSize: 11,
    color: 'black',
    text,
    colSpan: 1,
    alignment: 'center'
  }
}

const getSubHeader = (text) => ({ ...getBaseHeader(PdfUtil.ucfirst(text)), fontSize: 10 })
const getText = (text) => ({ text, fontSize: 9, color: 'black' })

const getResultGerenalTable = (vueInstance, questionEvaluations) => {
  const table = {
    widths: ['5%', '53%', '14%', '14%', '14%'],
    headerRows: 1,
    body: [
      [
        '',
        '',
        getBaseHeader(vueInstance.$t('Reports.Individual.results_title_1_table_header_1')),
        getBaseHeader(vueInstance.$t('Reports.Individual.results_title_1_table_header_2')),
        getBaseHeader(vueInstance.$t('Reports.Individual.results_title_1_table_header_3'))
      ]
    ]
  }
  const rowColors = []
  for (const dimention of Object.keys(vueInstance.answersDimention)) {
    const dimentionQuestion = questionEvaluations[dimention]
    table.body.push([
      '',
      getSubHeader(dimentionQuestion.name.es),
      getScore(vueInstance.answersDimention[dimention].score.auto, vueInstance.round),
      getScore(vueInstance.answersDimention[dimention].score.team, vueInstance.round),
      getTrend(vueInstance.hasPrevious, vueInstance.answersDimention[dimention].score, 'team', vueInstance.round)
    ])
    rowColors.push(colors[dimention].color)
    for (let attr = 1; attr <= 3; attr++) {
      table.body.push([
        '',
        { ...getSubHeader(dimentionQuestion[`attr_${attr}`].name.es), alignment: 'letf' },
        getScore(vueInstance.answersDimention[dimention].attributes[`attr_${attr}`].score.auto, vueInstance.round),
        getScore(vueInstance.answersDimention[dimention].attributes[`attr_${attr}`].score.team, vueInstance.round),
        getTrend(vueInstance.hasPrevious, vueInstance.answersDimention[dimention].attributes[`attr_${attr}`].score, 'team', vueInstance.round)
      ])
      rowColors.push(colors[dimention][`attr_${attr}`])
    }
  }

  return {
    table,
    layout: {
      fontSize: 10,
      fillColor: (rowIndex, node, columnIndex) => {
        if (rowIndex === 0) {
          return '#fff'
        }
        return !columnIndex ? rowColors[rowIndex - 1] : '#fff'
      }
    }
  }
}

const getLegendTable = (vueInstance) => {
  return {
    table: {
      widths: ['0.5%', '3%', '0.5%', '22%', '4%', '13%', '4%', '13%', '0.5%', '3%', '0.5%', '16%', '0.5%', '3%', '0.5%', '16%'],
      headerRows: 0,
      body: [
        [
          '', '', '',
          getText(vueInstance.$t('Reports.Individual.result_legend_table_1')),
          {
            image: X64,
            width: 15,
            height: 15,
            margin: [0, 0, 0, 0]
          },
          getText(vueInstance.$t('Reports.Individual.result_legend_table_2')),
          {
            image: Diamond64,
            width: 18,
            height: 18,
            margin: [0, 0, 0, 0]
          },
          getText(vueInstance.$t('Reports.Individual.result_legend_table_3')),
          '', '', '',
          getText(vueInstance.$t('Reports.Individual.result_legend_table_4')),
          '', '', '',
          getText(vueInstance.$t('Reports.Individual.result_legend_table_5'))
        ]
      ]
    },
    fontSize: 8,
    margin: [-20, 5, 0, 0],
    layout: {
      fillColor: (rowIndex, node, columnIndex) => {
        return resultLegend(columnIndex)
      },
      hLineWidth: () => 1,
      vLineWidth: () => 1,
      hLineColor: () => 'white',
      vLineColor: () => 'white'
    }
  }
}

const getRangeHeaderTable = () => {
  const widths = []
  const row = []
  for (let i = 0; i < 20; i++) {
    widths.push('*')
    row.push('')
  }
  return {
    table: {
      widths,
      headerRows: 0,
      body: [row, row]
    },
    fontSize: 5,
    margin: [0, 5, 0, 0],
    layout: {
      hLineWidth: (i) => i === 1,
      vLineWidth: (i) => !i || !(i % 4) ? 2 : 0,
      hLineColor: () => 'black',
      vLineColor: () => 'black'
    }
  }
}

const getRangeIndex = (range) => {
  const r = [
    (range.min - 1) * 4,
    (range.max - 1) * 4
  ]
  return [
    r[0] !== 20 ? r[0] : 19,
    r[1] !== 20 ? r[1] : 19
  ]
}

const getScoreIndex = (score) => {
  const s = Math.floor((score - 1) / 0.25)
  return s !== 20 ? s : 19
}

const isChange = (elements, dimention, attribute, behavior) => {
  return elements.find(item => item.dimention === dimention && item.attribute === attribute && item.behavior === behavior)
}

const getColorRangeTable = (idx, range, colorChange) => {
  if ((range[0] === range[1] && idx === range[0]) || (range[0] <= idx && range[1] >= idx)) {
    const resultColors = ColorUtils.results()
    if (colorChange) {
      if (isChange(colorChange.strengths, colorChange.dimention, colorChange.attribute, colorChange.behavior)) {
        return resultColors.rangeStrengths
      } else if (isChange(colorChange.improvement, colorChange.dimention, colorChange.attribute, colorChange.behavior)) {
        return resultColors.rangeImprovement
      }
    }
    return resultColors.range
  }
  return '#fff'
}

const getImageRange = (image) => ({
  image: image,
  width: 10,
  height: 7,
  margin: [0, 0, 0, 0]
})

const getRangeTable = (score, teamScore, range, colorChange) => {
  const idxRange = getRangeIndex(range)
  const idxScore = getScoreIndex(score)
  const idxTeamScore = getScoreIndex(teamScore)
  const widths = []
  const row = []

  for (let i = 0; i < 20; i++) {
    widths.push(2.25)
    if (i === idxScore && idxScore === idxTeamScore) {
      row.push(getImageRange(XDiamond64))
    } else if (i === idxScore) {
      row.push(getImageRange(X64))
    } else if (i === idxTeamScore) {
      row.push(getImageRange(Diamond64))
    } else {
      row.push('')
    }
  }
  return {
    table: {
      widths,
      headerRows: 0,
      body: [row]
    },
    fontSize: 5,
    margin: [0, 0, 0, 0],
    layout: {
      fillColor: (rowIndex, node, columnIndex) => {
        return getColorRangeTable(columnIndex, idxRange, colorChange)
      },
      hLineWidth: () => 0,
      vLineWidth: () => 0
    }
  }
}

const getResultTable = (vueInstance, base, dimention, questionEvaluations) => {
  const table = {
    widths: ['53%', '28%', '7%', '5%', '7%'],
    headerRows: 1,
    body: [
      [
        '', getRangeHeaderTable(),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}header_1`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}header_2`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}header_3`))
      ]
    ]
  }

  const dimentionQuestion = questionEvaluations[dimention]
  table.body.push([
    getSubHeader(dimentionQuestion.name.es),
    getRangeTable(
      vueInstance.answersDimention[dimention].score.auto,
      vueInstance.answersDimention[dimention].score.team,
      vueInstance.answersDimention[dimention].range
    ),
    getScore(vueInstance.answersDimention[dimention].score.auto, vueInstance.round),
    getScore(vueInstance.answersDimention[dimention].score.team, vueInstance.round),
    getTrend(vueInstance.hasPrevious, vueInstance.answersDimention[dimention].score, 'auto', vueInstance.round)
  ])
  for (let attr = 1; attr <= 3; attr++) {
    table.body.push([
      getSubHeader(dimentionQuestion[`attr_${attr}`].name.es),
      getRangeTable(
        vueInstance.answersDimention[dimention].attributes[`attr_${attr}`].score.auto,
        vueInstance.answersDimention[dimention].attributes[`attr_${attr}`].score.team,
        vueInstance.answersDimention[dimention].attributes[`attr_${attr}`].range
      ),
      getScore(vueInstance.answersDimention[dimention].attributes[`attr_${attr}`].score.auto, vueInstance.round),
      getScore(vueInstance.answersDimention[dimention].attributes[`attr_${attr}`].score.team, vueInstance.round),
      getTrend(vueInstance.hasPrevious, vueInstance.answersDimention[dimention].attributes[`attr_${attr}`].score, 'auto', vueInstance.round)
    ])
    for (let behavior = 1; behavior <= 5; behavior++) {
      table.body.push([
        getText(dimentionQuestion[`attr_${attr}`][`behavior_0${behavior}`].generalEvaluation.es),
        getRangeTable(
          vueInstance.answersDimention[dimention].attributes[`attr_${attr}`].behavior[`behavior_${behavior}`].score.auto,
          vueInstance.answersDimention[dimention].attributes[`attr_${attr}`].behavior[`behavior_${behavior}`].score.team,
          vueInstance.answersDimention[dimention].attributes[`attr_${attr}`].behavior[`behavior_${behavior}`].range,
          {
            strengths: vueInstance.strengthsItems,
            improvement: vueInstance.improvementItems,
            dimention,
            attribute: `attr_${attr}`,
            behavior: `behavior_${behavior}`
          }
        ),
        getScore(vueInstance.answersDimention[dimention].attributes[`attr_${attr}`].behavior[`behavior_${behavior}`].score.auto, vueInstance.round),
        getScore(vueInstance.answersDimention[dimention].attributes[`attr_${attr}`].behavior[`behavior_${behavior}`].score.team, vueInstance.round),
        getTrend(vueInstance.hasPrevious, vueInstance.answersDimention[dimention].attributes[`attr_${attr}`].behavior[`behavior_${behavior}`].score, 'auto', vueInstance.round)
      ])
    }
  }

  return {
    table,
    fontSize: 8,
    margin: [-20, 0, 0, 0]
  }
}

export default {
  methods: {
    $generateResults () {
      return [
        PdfUtil.generateTitle(this.$t('Reports.Individual.results_title_1'), 'before'),
        getResultGerenalTable(this, this.evaluation.questionnaire.evaluations),
        PdfUtil.generateParagraph(this.$t('Reports.Individual.results_info_1')),
        { text: '\n' },
        PdfUtil.generateParagraph(this.$t('Reports.Individual.results_info_2')),
        {
          image: this.pieChart,
          width: 500,
          height: 300,
          margin: [0, 0, 0, 0]
        },
        PdfUtil.generateTitleLandscape(this.$t('Reports.Individual.results_title_2'), 'before'),
        PdfUtil.generateParagraph(this.$t('Reports.Individual.results_info_3'), { fontSize: 8 }),
        getLegendTable(this),
        getResultTable(this, 'results_title_3_table_', 'persons', this.evaluation.questionnaire.evaluations),
        PdfUtil.generateTitleLandscape(this.$t('Reports.Individual.results_title_3'), 'before'),
        PdfUtil.generateParagraph(this.$t('Reports.Individual.results_info_4'), { fontSize: 8 }),
        getLegendTable(this),
        getResultTable(this, 'results_title_3_table_', 'organization', this.evaluation.questionnaire.evaluations),
        PdfUtil.generateTitleLandscape(this.$t('Reports.Individual.results_title_4'), 'before'),
        PdfUtil.generateParagraph(this.$t('Reports.Individual.results_info_5'), { fontSize: 8 }),
        getLegendTable(this),
        getResultTable(this, 'results_title_3_table_', 'results', this.evaluation.questionnaire.evaluations)
      ]
    }
  }
}
