
import PdfUtil from '../utils/pdf'

const getScore = (score, round) => {
  return { text: round(score, 2), alignment: 'center', fontSize: 10 }
}

const getBaseHeader = (text, colSpan, fontSize) => {
  return {
    bold: true,
    fontSize: fontSize || 11,
    color: 'black',
    text: text,
    colSpan: colSpan || 1,
    alignment: 'center'
  }
}
const getSubHeader = (text) => ({ ...getBaseHeader(PdfUtil.ucfirst(text)), fontSize: 10 })
const getText = (text) => ({ text: text, fontSize: 9, color: 'black' })
const getNode = () => ({ alignment: 'center', text: '--' })
const getBehavior = (behavior) => {
  const b = behavior.split('_')
  return `${b[0]}_0${b[1]}`
}

const getResultTable = (vueInstance, base, elements, questionEvaluations) => {
  const table = {
    widths: ['12%', '16%', '48%', '10%', '7%', '7%'],
    headerRows: 2,
    body: [
      [getBaseHeader(vueInstance.$t(`Reports.Individual.${base}header`), 6), '', '', '', '', ''],
      [
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}sub_header_1`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}sub_header_2`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}sub_header_3`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}sub_header_4`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}sub_header_5`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}sub_header_6`))
      ]
    ]
  }

  for (const item of elements) {
    if (item.dimention && item.attribute && item.behavior) {
      table.body.push([
        getSubHeader(questionEvaluations[item.dimention].name.es),
        getSubHeader(questionEvaluations[item.dimention][item.attribute].name.es),
        getText(questionEvaluations[item.dimention][item.attribute][getBehavior(item.behavior)].generalEvaluation.es),
        getScore(item.score, vueInstance.round),
        getScore(item.second, vueInstance.round),
        getScore(item.gap, vueInstance.round)
      ])
    } else {
      table.body.push([getNode(), getNode(), getNode(), getNode(), getNode(), getNode()])
    }
  }

  return {
    table,
    margin: [0, 7, 0, 0]
  }
}

export default {
  methods: {
    $generateGaps () {
      return [
        PdfUtil.generateTitleLandscape(this.$t('Reports.Individual.gaps_title'), 'before'),
        PdfUtil.generateParagraph(this.$t('Reports.Individual.gaps_title_info'), { fontSize: 8 }),
        getResultTable(this, 'gaps_table_1_', this.positiveGaps, this.evaluation.questionnaire.evaluations),
        getResultTable(this, 'gaps_table_2_', this.negativeGaps, this.evaluation.questionnaire.evaluations)
      ]
    }
  }
}
