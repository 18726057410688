
import PdfUtil from '../utils/pdf'

const getTrend = (trend, round) => {
  return { text: round(trend, 2), alignment: 'right', margin: [0, 0, 25, 0], fontSize: 10 }
}

const getScore = (score, round) => {
  return { text: round(score, 2), alignment: 'center', fontSize: 10 }
}

const getBaseHeader = (text, colSpan, fontSize) => {
  return {
    bold: true,
    fontSize: fontSize || 10,
    color: 'black',
    text: text,
    colSpan: colSpan || 1,
    alignment: 'center'
  }
}
const getSubHeader = (text) => getBaseHeader(PdfUtil.ucfirst(text))
const getText = (text) => ({ text: text, fontSize: 9, color: 'black' })
const getBehavior = (behavior) => {
  const b = behavior.split('_')
  return `${b[0]}_0${b[1]}`
}

const getNode = () => ({ alignment: 'center', text: '--' })

const getTrendTable = (vueInstance, base, elements, questionEvaluations) => {
  const table = {
    widths: ['10%', '16%', '46%', '10%', '8%', '10%'],
    headerRows: 2,
    body: [
      [getBaseHeader(vueInstance.$t(`Reports.Individual.${base}title`), 6), '', '', '', '', ''],
      [
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}header_1`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}header_2`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}header_3`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}header_4`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}header_5`)),
        getBaseHeader(vueInstance.$t(`Reports.Individual.${base}header_6`))
      ]
    ]
  }
  if (!vueInstance.hasPrevious) {
    for (let i = 0; i < 6; i++) {
      table.body.push([getNode(), getNode(), getNode(), getNode(), getNode(), getNode()])
    }
  } else {
    for (const item of elements) {
      if (item.dimention && item.attribute && item.behavior) {
        table.body.push([
          getSubHeader(questionEvaluations[item.dimention].name.es),
          getSubHeader(questionEvaluations[item.dimention][item.attribute].name.es),
          getText(questionEvaluations[item.dimention][item.attribute][getBehavior(item.behavior)].generalEvaluation.es),
          getScore(item.score, vueInstance.round),
          getScore(item.previous, vueInstance.round),
          getTrend(item.trend, vueInstance.round)
        ])
      } else {
        table.body.push([getNode(), getNode(), getNode(), getNode(), getNode(), getNode()])
      }
    }
  }

  return {
    table,
    fontSize: 8
  }
}

export default {
  methods: {
    $generateTrend () {
      return [
        PdfUtil.generateTitleLandscape(this.$t('Reports.Individual.trend_title'), 'before'),
        PdfUtil.generateParagraph(this.$t('Reports.Individual.trend_info'), { fontSize: 8 }),
        getTrendTable(this, 'trend_table_1_', this.positiveTrends, this.evaluation.questionnaire.evaluations),
        { text: '\n' },
        getTrendTable(this, 'trend_table_2_', this.negativeTrends, this.evaluation.questionnaire.evaluations),
        {
          columns: PdfUtil.generateCenteredText(!this.hasPrevious ? this.$t('Reports.Individual.trend_msg') : '', 14, true),
          margin: [0, 10, 0, 0]
        }
      ]
    }
  }
}
