
import PdfUtil from '../utils/pdf'

import CoverBase64 from '../base64Files/cover'

const fontSizeBylength = [
  { min: 0, max: 30, fontSize: 20 },
  { min: 31, max: 34, fontSize: 18 },
  { min: 35, max: 38, fontSize: 16 },
  { min: 39, max: 42, fontSize: 14 },
  { min: 43, max: 57, fontSize: 12 },
  { min: 57, max: 70, fontSize: 10 },
  { min: 70, max: 9999999, fontSize: 8 }
]
const getFontSizeBylength = (l) => fontSizeBylength.find((fsbl) => fsbl.min <= l && fsbl.max >= l).fontSize

const execRound = (score, round, decimal) => {
  return +score === 100 ? score : round(score, decimal)
}

const getScore = (score, round) => {
  return { text: `${execRound(score, round, 2)}%`, alignment: 'center' }
}

const getScoreText = (score) => {
  return { text: score, alignment: 'center' }
}

const getBaseHeader = (text) => {
  return {
    bold: true,
    fontSize: 12,
    color: 'black',
    text,
    colSpan: 1,
    alignment: 'center'
  }
}

const getCoverTable = (vueInstance) => {
  const table = {
    widths: ['33%', '33%', '34%'],
    headerRows: 1,
    body: [
      [
        getBaseHeader(vueInstance.$t('Reports.Individual.cover_table_header_1')),
        getBaseHeader(vueInstance.$t('Reports.Individual.cover_table_header_2')),
        getBaseHeader(vueInstance.$t('Reports.Individual.cover_table_header_3'))
      ],
      [
        getScoreText(vueInstance.expectedPolls),
        getScoreText(vueInstance.completedPolls),
        getScore((vueInstance.completedPolls / vueInstance.expectedPolls) * 100, vueInstance.round)
      ]
    ]
  }

  return {
    table,
    fontSize: 10,
    layout: {
      width: '75%'
    }
  }
}

export default {
  data () {
    return {
      today: new Date()
    }
  },
  methods: {
    $generateCover () {
      const fontSize = getFontSizeBylength((this.evaluation.name || '').length)
      return [
        {
          image: CoverBase64,
          width: 610,
          height: 800,
          aligment: 'center',
          margin: [0, 0, 0, 0],
          absolutePosition: { x: 0, y: 0 }
        },
        {
          margin: [0, 150, 0, 0],
          columns: PdfUtil.generateCenteredText(this.$t('Reports.Individual.title').toUpperCase(), 26, true, false, false)
        },
        {
          ...getCoverTable(this),
          margin: [0, 360, 0, 0]
        },
        {
          columns: PdfUtil.generateCenteredText(this.evaluation.name, fontSize, false),
          margin: [0, 10, 0, 0]
        },
        {
          columns: PdfUtil.generateCenteredText(this.evaluatedName, 20, false),
          margin: [0, 10, 0, 0]
        },
        {
          margin: [0, 10, 0, 0],
          columns: PdfUtil.generateCenteredText(this.user.enterprise.name, 20, true)
        },
        {
          columns: PdfUtil.generateCenteredText(`${this.$t(`Reports.Individual.cover_months[${this.today.getMonth()}]`)} ${this.today.getDate()} de ${this.today.getFullYear()}`, 15, true)
        }
      ]
    }
  }
}
