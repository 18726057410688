<template>
  <v-dialog persistent
    v-model="show"
    max-width="560px"
  >
    <v-card>
      <v-card-text class="text-center grey--text text--darken-1 pt-6">
        <h2 class="mb-5 title">
          {{ $t('Views.Evaluations.report.prev_assesment_dialog_title') }}.
        </h2>
        <p class="mb-7" v-html="$t('Views.Evaluations.report.prev_assesment_dialog_description')"></p>
        <x-inputs-autocomplete light clearable
          :items="previousAssessments"
          :input="previousAssessment"
          :label="$t('Views.Evaluations.report.previous_assessment')"
          name="prev_poll"
          @updateInput="($event) => previousAssessment = $event"
        />
      </v-card-text>
      <v-card-actions>
        <v-row class="ma-0">
          <v-col cols="12" sm="6" order-sm="1" order="2">
            <v-btn block large
              :disabled="previousAssessment !== undefined"
              @click="$emit('skip')"
            >
              {{ $t('Views.Evaluations.report.input_skip') }}
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6" order-sm="2" order="1">
            <v-btn block large
              class="white--text"
              color="primary"
              :disabled="previousAssessment === undefined"
              @click="$emit('continue', previousAssessment)"
            >
              {{ $t('Views.Evaluations.evaluation.helpDialog.input_continue') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'previous-polls-dialog',
  props: {
    show: Boolean,
    previousAssessments: Array
  },
  data () {
    return {
      previousAssessment: undefined
    }
  },
  watch: {
    show (val) {
      if (!val) {
        this.previousAssessment = undefined
      }
    }
  }
})
</script>
