
<template>
  <div style="display:inline;">
    <v-btn
      @click="openPdf"
      color="success"
      :loading="lockPdfButton"
      large
      :disabled="thread.status !== 'completed'"
    >
      <v-icon>mdi-file-pdf</v-icon>
      <span v-if="thread.status === 'pending'">{{ $t('Views.Report.threadIndividualReportExec.input_generating_report') }}</span>
      <span v-else-if="thread.status === 'in_action' || thread.status === 'in_progress'">{{ $t('Views.Report.threadIndividualReportExec.input_generating_report') + ` ${thread.data.progress}%` }}</span>
      <span v-else-if="thread.status === 'failed'">{{ $t('Views.Report.threadIndividualReportExec.input_failed_generation') }}</span>
      <span v-else>{{ $t('Views.Report.threadIndividualReportExec.input_download_report') }}</span>
    </v-btn>
    <br/>
    <v-btn
      v-if="thread.status !== 'completed' && thread.status !== 'failed'"
      text
      color="info"
      @click="refreshPage"
    >
      <v-icon>mdi-refresh</v-icon>
      {{ $t('Views.Report.threadIndividualReportExec.input_update_progress') }}
    </v-btn>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import is from 'is_js'

import cover from './mixins_individual/cover'
import initial from './mixins_individual/initial'
import index from './mixins_individual/index'
import introduction from './mixins_organizational/introduction'
import results from './mixins_individual/results'
import rank from './mixins_individual/rank'
import gaps from './mixins_individual/gaps'
import trend from './mixins_individual/trend'
import plan from './mixins_individual/plan'

const pdfMake = require('pdfmake/build/pdfmake')
const pdfFonts = require('pdfmake/build/vfs_fonts.js')
require('echarts-wordcloud')
pdfMake.vfs = pdfFonts.pdfMake.vfs

export default Vue.extend({
  name: 'thread-individual-report-exec',
  mixins: [
    cover,
    initial,
    index,
    introduction,
    results,
    rank,
    gaps,
    trend,
    plan
  ],
  props: {
    pollId: String,
    thread: Object,
    evaluatedName: String
  },
  data () {
    return {
      lockPdfButton: false,
      evaluation: {},
      answersDimention: {},
      completedPolls: 0,
      expectedPolls: 0,
      pieChart: null,
      renderPart: {
        pieChart: false
      }
    }
  },
  watch: {
    renderPart: {
      async handler () {
        const hasFalses = Object.values(this.renderPart).includes(false)
        if (!hasFalses) await this.renderPdf()
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.session.user
    })
  },
  methods: {
    async openPdf () {
      this.$store.dispatch('loading/show')
      this.lockPdfButton = true
      await this.$getInitialData()
    },
    async renderPdf () {
      this.$emit('render-pdf')
      const configuration = await this.$getConfiguration()
      if (is.edge() || is.ie()) {
        const pdfDocGenerator = pdfMake.createPdf(configuration)
        pdfDocGenerator.getBlob((blob) => {
          window.navigator.msSaveBlob(blob, `${this.evaluation.name} - (${this.evaluatedName}).pdf`)
          this.$store.dispatch('loading/hide')
          this.lockPdfButton = false
        })
      } else {
        pdfMake.createPdf(configuration).download(`${this.evaluation.name} - (${this.evaluatedName}).pdf`, () => {
          this.$store.dispatch('loading/hide')
          this.lockPdfButton = false
        })
      }
    },
    round (value, decimals) {
      if (isNaN(Number(value))) {
        return '--'
      }
      if ((value * 100) < 1 && (value * 100) > -1) {
        value = 0
      }
      return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals).toFixed(decimals)
    },
    refreshPage () {
      window.location.reload()
    }
  }
})
</script>
